<style scoped></style>
<template>
  <div :style="{ backgroundColor: backgroundColor }">
    <v-card-title class="white--text">{{ title }}</v-card-title>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CardHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#1b2643',
    },
  },
})
</script>
