<template>
  <v-form>
    <v-container>
      <v-snackbar
        v-model="snackbarActionBtn"
        :vertical="vertical"
        :timeout="-1"
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackbarColor"
            text
            v-bind="attrs"
            @click="closeSnackbar"
          >
            CLOSE
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SnackBar',
  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
    snackbarTimeout: { type: Number, default: 6000 }, // timeoutのデフォルト値：6000ms
  },
  data() {
    return {
      snackbarActionBtn: false,
      vertical: true,
      activeTimeout: -1,
    }
  },
  watch: {
    snackbarAction(newValue) {
      this.snackbarActionBtn = newValue
      this.setTimeout()
    },
  },
  methods: {
    // snackBar表示のタイムアウトはv-snackbar内のtimeout指定ではなく以下のsetTimeout()で実行
    // タイムアウトでsnackbar消滅時にcloseSnackBar()を実行し正常終了させる
    setTimeout() {
      window.clearTimeout(this.activeTimeout)
      this.activeTimeout = window.setTimeout(() => {
        this.closeSnackbar()
      }, this.snackbarTimeout)
    },
    closeSnackbar() {
      this.snackbarActionBtn = false
      this.$emit('closeSnackbar')
    },
  },
})
</script>
