<template>
  <v-main>
    <router-view />
    <bottom-bar />
  </v-main>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import BottomBar from '@/components/Common/BottomBarComponent.vue'

export default defineComponent({
  name: 'TopLayout',
  components: {
    BottomBar,
  },
  setup() {
    return {}
  },
})
</script>
