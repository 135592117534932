<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}

td {
  border-bottom: 1px solid #dbdbdb;
}
</style>

<template>
  <div>
    <v-card v-show="!show" width="60vw" class="ma-10 card_shadow">
      <v-form ref="form">
        <v-container class="pt-5">
          <v-row class="headline mt-n5">
            <v-col class="active_text">
              <p>{{ $t('securitySettings.securitySetting') }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-10 mt-10 mr-10">
            <p
              class="text-body-2"
              v-html="$t('securitySettings.canChangePassword')"
            ></p>
          </v-row>
          <v-row v-if="isSignInError" justify="center" class="mb-n7">
            <v-col cols="10">
              <v-alert icon="mdi-alert-circle" type="error">
                {{ $t('securitySettings.passwordIncorrect') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                v-model="password"
                :label="$t('common.password')"
                persistent-placeholder
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-4 mt-2">
            <v-btn
              color="#1b2643"
              @click="verifyPassword"
              class="white--text btn_shadow"
            >
              {{ $t('securitySettings.next') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <v-card v-show="show" width="60vw" class="ma-10 card_shadow">
      <v-form ref="form">
        <v-container class="pt-5">
          <v-row class="headline mt-n5">
            <v-col class="active_text">
              <p>{{ $t('securitySettings.securitySetting') }}</p>
            </v-col>
          </v-row>
          <v-row class="mt-15" justify="center">
            <v-col cols="10">
              <v-text-field
                v-model="email"
                :label="$t('common.email')"
                persistent-placeholder
                outlined
                readonly
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <input-new-pass
                :isSecurity="true"
                @setPassword="setPassword"
                @disableBtn="disableBtn"
              />
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-n7">
            <v-col cols="10">
              <p
                v-html="$t('securitySettings.passwordCriteriaDescription')"
              ></p>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-5">
            <v-btn
              :disabled="isDisable"
              color="#1b2643"
              @click="onClick"
              class="white--text btn_shadow"
            >
              {{ $t('securitySettings.change') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { updatePassword } from 'firebase/auth'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import InputNewPass from '@/components/Dashboard/PageViewItems/InputNewPasswordField.vue'

export default defineComponent({
  name: 'SecurityPage',
  components: {
    InputNewPass,
  },
  setup() {
    const auth = getAuth()
    const user = auth.currentUser
    let email = ''
    if (user && user.email) {
      email = user.email
    }
    return {
      user,
      email,
    }
  },
  data() {
    return {
      password: '',
      isDisable: true,
      showPassword: false,
      show: false,
      isSignInError: false,
    }
  },
  methods: {
    onClick() {
      if (this.user != null) {
        updatePassword(this.user, this.password).then(() => {
          // console.log('パスワードの変更に成功しました')
          this.$router.push({
            name: 'Sign In',
            params: {
              snackbarAction: 'true',
              snackbarText: 'パスワードを変更しました',
              snackbarColor: 'pink',
            },
          })
        })
      }
    },

    verifyPassword() {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.email, this.password)
        // .then((user) => {
        .then(() => {
          // console.log('認証に成功しました: ', user.user.email)
          this.show = true
        })
        .catch((error) => {
          console.log('error code:', error.code)
          // リリース前にconsole表示は削除
          // const errorCode = error.code
          // const errorMessage = error.message
          // console.log(
          //   'Firebase Authenticationからエラーが返されました: ',
          //   errorCode,
          //   ', ',
          //   errorMessage
          // )
          this.isSignInError = true
        })
    },

    // コンポーネントからの値の受け取り
    setPassword(pass: string) {
      this.password = pass
    },
    disableBtn(isDisable: boolean) {
      this.isDisable = isDisable
    },
  },
})
</script>
