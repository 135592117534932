<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}
.title {
  font: normal normal normal 24px/36px Roboto;
}
.filled_noline >>> .v-input__slot::before {
  border-style: none !important;
}
.under-line {
  text-decoration: underline;
}
</style>

<template>
  <v-card width="60vw" class="ma-10 card_shadow">
    <v-form ref="form">
      <v-container class="pt-5">
        <v-row class="headline mt-n5">
          <v-col class="active_text">
            <p>{{ $t('signOut.signOutTitle') }}</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-8">
          <p>{{ $t('signOut.signOutConfirmMessage') }}</p>
        </v-row>
        <v-row justify="center">
          <v-btn
            color="#1b2643"
            class="mb-7 white--text btn_shadow"
            @click="onClickSignOut"
          >
            {{ $t('signOut.signOutTitle') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { auth } from '@/plugins/firebase'
import { signOut } from 'firebase/auth'

export default defineComponent({
  name: 'SignOutView',
  data() {
    return {
      //   snackbarAction: false,
    }
  },
  methods: {
    onClickSignOut() {
      // サインアウト
      signOut(auth)
        .then(() => {
          this.$store.commit('reset', this.$store.state)
          //console.log('サインアウトに成功しました')
          this.$router.push({
            name: 'Sign In',
          })
        })
        .catch((error) => {
          // console.log(`サインアウト時にエラーが発生しました: (${error})`)
          console.log('error code:', error.code)
        })
    },
  },
})
</script>
