<style scoped>
.card-text {
  padding-left: 11px;
  /* padding-top: 3px; */
  margin-top: -27px;
}
</style>

<template>
  <div>
    <!-- <v-card outlined width="30vw" height="12vh"> -->
    <v-card outlined>
      <v-card-text class="card-text text-caption">
        <span style="background-color: white">{{ cardTitle }}</span>
      </v-card-text>
      <v-container>
        <v-row justify="center" class="mt-n10 mb-n7">
          <v-col cols="10" lg="6" class="mb-n10">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required, rules.dateFormat]"
                  maxlength="10"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="7" lg="5">
            <v-combobox
              v-model="time"
              persistent-hint
              label="time"
              :items="times"
              :rules="[rules.required, rules.required2, rules.timeFormat]"
              maxlength="5"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import moment from 'moment-timezone'

const interval = 15
const datesContent = [] as string[]
for (let h = 0; h < 24; h++) {
  for (let m = 0; m < 60; m += interval) {
    let time = String(('0' + h).slice(-2)) + ':' + String(('0' + m).slice(-2))
    datesContent.push(time)
  }
}

export default defineComponent({
  name: 'SetSchedule',

  props: {
    cardTitle: { type: String, default: '' },
    unixDateTime: { type: Number, default: 0 },
  },

  data() {
    return {
      date: moment().tz('Asia/Tokyo').format('YYYY-MM-DD'),
      menu: false,
      times: datesContent,
      time: 'hh:mm',
      dateTime: new Date(moment().tz('Asia/Tokyo').format()),
      unixDateTimeField: 0,

      rules: {
        required: (value: string) => !!value || '未入力です',
        required2: (value: string) => value !== 'hh:mm' || '未入力です',
        dateFormat: (value: string) =>
          /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value) ||
          'yyyy-mm-ddの形で入力してください',
        timeFormat: (value: string) =>
          /([01][0-9]|2[0-3]):[0-5][0-9]/.test(value) ||
          'hh:mmの形で入力してください',
      },
    }
  },

  created() {
    this.initialize()
  },

  mounted() {
    if (this.unixDateTimeField != 0) {
      this.UnixTimeTrancerate(this.unixDateTimeField)
    }
  },

  watch: {
    date() {
      if (
        /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(this.date) &&
        /([01][0-9]|2[0-3]):[0-5][0-9]/.test(this.time)
      ) {
        this.formatDateTime()
        this.$emit('changeDateTime', this.dateTime.getTime())
      }
    },
    time() {
      if (
        /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(this.date) &&
        /([01][0-9]|2[0-3]):[0-5][0-9]/.test(this.time)
      ) {
        this.formatDateTime()
        this.$emit('changeDateTime', this.dateTime.getTime())
      }
    },
    unixDateTime(newValue) {
      this.unixDateTimeField = newValue
      this.UnixTimeTrancerate(newValue)
      this.formatDateTime()
    },
  },

  methods: {
    initialize() {
      this.unixDateTimeField = this.unixDateTime
    },

    formatDateTime() {
      this.dateTime = new Date(this.date + ':' + this.time + '+09:00')
    },

    UnixTimeTrancerate(UnixTime: number) {
      this.date = moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD')
      this.time = moment(UnixTime).tz('Asia/Tokyo').format('HH:mm')
    },
  },
})
</script>
