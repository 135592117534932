<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}

td {
  border-bottom: 1px solid #dbdbdb;
}
</style>

<template>
  <v-card width="60vw" class="ma-10 card_shadow">
    <v-form ref="form">
      <v-container class="pt-5">
        <v-row class="headline mt-n5">
          <v-col class="active_text">
            <p>{{ $t('newLearners.simpleSetupDescription') }}</p>
          </v-col>
        </v-row>
        <v-row class="ml-10 mt-10 mr-10">
          <p
            class="text-body-2"
            v-html="$t('newLearners.userTestSetupGuide')"
          ></p>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="7">
            <v-file-input
              accept="text/csv"
              :label="$t('newLearners.newAccountFile')"
              value="a"
              @change="getFileContent"
              persistent-placeholder
              outlined
            ></v-file-input>
          </v-col>
          <v-col cols="5">
            <p class="mt-4">{{ $t('newLearners.formatCSV') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

type NewStudentType = {
  tempId: string
  studentId: string
  tempPassword: string
}

export default defineComponent({
  name: 'NewLearnersView',
  data() {
    return {
      fileName: '',
      students: [] as NewStudentType[],
    }
  },
  methods: {
    getFileContent(e: File) {
      this.fileName = e.name
      const reader = new FileReader()
      const file = e
      const students: NewStudentType[] = []

      const loadFunc = () => {
        if (reader.result !== null) {
          const lines = (reader.result as string).split('\n')
          lines.forEach((element: string) => {
            const studentData = element.split(',')

            // ここの条件分岐はcsvの仕様によって変更
            if (studentData.length === 3) {
              const student = {
                tempId: studentData[0],
                studentId: studentData[1],
                tempPassword: studentData[2],
              }
              students.push(student)
            }
          })
        }
        this.students = students
      }

      reader.onload = loadFunc
      reader.readAsBinaryString(file)
    },
  },
})
</script>
