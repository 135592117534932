<style scoped>
.icon-style {
  margin: 12px;
  bottom: 15px;
  font-size: 32px;
}

.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <div>
    <v-menu top offset-y nudge-top="10">
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind="{ on, attrs }"></slot>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in languageListItems"
          :key="index"
          @click="switchLanguage(item.value)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { i18n } from '@/locales/i18n'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
  data: () => ({
    languageListItems: [
      { title: '日本語', value: 'ja' },
      { title: 'English', value: 'en' },
    ],
  }),
  methods: {
    switchLanguage(value: string) {
      window.localStorage.setItem('language', value)
      i18n.locale = value
    },
  },
})
</script>
