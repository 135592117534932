<style scoped>
.filled_noline >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<template>
  <v-form>
    <!-- セキュリティページ -->
    <v-row v-if="isSecurity">
      <v-col cols="12">
        <v-text-field
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.min,
            rules.characterType,
            rules.characterSelect,
          ]"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
          v-model="password1"
          :label="$t('common.password')"
          persistent-placeholder
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="isSecurity">
      <v-col cols="12">
        <v-text-field
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword2 ? 'text' : 'password'"
          @click:append="showPassword2 = !showPassword2"
          v-model="password2"
          :label="$t('common.passwordConfirm')"
          persistent-placeholder
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- サインアップページ -->
    <v-row v-if="isSignUp" justify="center" class="mt-n7">
      <v-col cols="10">
        <v-text-field
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.min,
            rules.characterType,
            rules.characterSelect,
          ]"
          :type="showPassword1 ? 'text' : 'password'"
          @click:append="showPassword1 = !showPassword1"
          v-model="password1"
          :label="$t('common.password')"
          filled
          dense
          class="filled_noline"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="isSignUp" justify="center" class="mt-n7">
      <v-col cols="10">
        <v-text-field
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword2 ? 'text' : 'password'"
          @click:append="showPassword2 = !showPassword2"
          v-model="password2"
          :label="$t('common.passwordConfirm')"
          filled
          dense
          class="filled_noline"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="samePass" justify="center">
      <p class="red--text">{{ $t('passwordVariation.passwordMismatch') }}</p>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'InputNewPass',
  props: {
    isSecurity: { type: Boolean, default: false },
    isSignUp: { type: Boolean, default: false },
  },
  data() {
    return {
      password1: '',
      password2: '',
      showPassword1: false,
      showPassword2: false,
      rules: {
        required: (value: string) =>
          !!value || this.$t('passwordVariation.required'),
        min: (value: string) =>
          value.length >= 8 ||
          this.$t('passwordVariation.minimumEightCharactersRequired'),
        characterType: (value: string) =>
          /^[a-zA-Z\d]+$/.test(value) ||
          this.$t('passwordVariation.alphanumericOnlyAllowed'),
        characterSelect: (value: string) =>
          (/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /\d+/.test(value)) ||
          this.$t('passwordVariation.useAllCasesAndNumber'),
      },
    }
  },
  computed: {
    inputed() {
      if (!/^[a-zA-Z\d]+$/.test(this.password1)) {
        return true
      } else if (!/[a-z]+/.test(this.password1)) {
        return true
      } else if (!/[A-Z]+/.test(this.password1)) {
        return true
      } else if (!/\d+/.test(this.password1)) {
        return true
      } else if (this.password1.length < 8) {
        return true
      } else if (this.password1 != this.password2) {
        return true
      } else {
        return false
      }
    },
    samePass() {
      if (this.password1 != this.password2) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    password1() {
      this.$emit('setPassword', this.password1)
      this.$emit('validatePassword', this.inputed)
    },
    password2() {
      this.$emit('setPassword', this.password1)
      this.$emit('validatePassword', this.inputed)
    },
  },
})
</script>
