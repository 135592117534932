<template>
  <v-dialog v-model="isLoadingField" persistent width="80%">
    <v-card>
      <v-card-title class="justify-center">Loading...</v-card-title>
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </div>
      <v-card-text class="text-center">
        {{ textField }}
      </v-card-text>
      <div>
        <v-progress-linear
          v-if="progressValueField !== 0"
          v-model="progressValueField"
          color="primary"
          height="15"
          :min="0"
          :max="100"
          >{{ progressValueField }} %
        </v-progress-linear>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LoadingCircle',
  props: {
    isLoading: { type: Boolean, default: false },
    text: { type: String, default: '' },
    progressValue: { type: Number, default: 0 },
  },
  data() {
    return {
      isLoadingField: false,
      textField: '',
      progressValueField: 0,
    }
  },
  mounted() {
    this.isLoadingField = this.isLoading
    this.textField = this.text
    this.progressValueField = this.progressValue
  },
  watch: {
    isLoading(newValue) {
      this.isLoadingField = newValue
    },
    text(newValue) {
      this.textField = newValue
    },
    progressValue(newValue) {
      this.progressValueField = newValue
    },
  },
  methods: {},
})
</script>
