<template>
  <v-footer padless app color="grey lighten-3">
    <v-col cols="12" class="text-center pa-0">
      <small> &copy; 2022 Equmenopolis Inc. </small>
    </v-col>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {}
  },
})
</script>
