<style scoped>
#cefr-table {
  width: 100%;
  overflow-x: hidden;
}
</style>

<template>
  <div id="app" class="pa-10">
    <v-card v-if="loading">
      <CardHeader :title="$t('conversationAnalysis.title').toString()" />
      <div class="d-flex flex-column pa-3 text-center" style="gap: 8px">
        <div class="text-center">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </div>
        <div class="text-body2">{{ $t('conversationAnalysis.loading') }}</div>
      </div>
    </v-card>
    <v-card v-else-if="error">
      <CardHeader :title="$t('conversationAnalysis.title').toString()" />
      <div class="d-flex flex-column pa-3" style="gap: 20px">
        <p class="mb-0">
          {{ $t('conversationAnalysis.conversationError') }}
        </p>
      </div>
    </v-card>
    <v-card v-else>
      <CardHeader :title="$t('conversationAnalysis.title').toString()">
        <div class="px-3">
          <v-tabs background-color="#1b2643" color="#eeeeee">
            <v-tab>{{ $t('conversationAnalysis.transcript') }}</v-tab>
          </v-tabs>
        </div>
      </CardHeader>
      <div class="d-flex flex-column pa-3" style="gap: 20px">
        <div>
          <p class="text-h6">{{ $t('conversationAnalysis.couponCode') }}</p>
          {{ couponCode }}
        </div>
        <div>
          <p class="text-h6">{{ $t('conversationAnalysis.dialogID') }}</p>
          {{ dialogId }}
        </div>

        <div style="width: 800px">
          <p class="text-h6">{{ $t('conversationAnalysis.cefrLevels') }}</p>
          <v-simple-table id="cefr-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in cefrTableHeaders"
                    :key="index"
                    v-html="header"
                    class="text-center"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    v-for="(cefrVal, index) in cefrTableBody"
                    :key="index"
                    :class="cefrStyleClass(cefrVal.label)"
                  >
                    {{ cefrVal.label }}
                    <span v-if="cefrVal.value !== -1"
                      >({{ cefrVal.value }})</span
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div>
          <p class="text-h6">
            {{ $t('conversationAnalysis.conversationAudio') }}
          </p>
          <audio
            controls
            style="width: 100%"
            preload="auto"
            controlsList="nodownload"
          >
            <source :src="audio" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        <div id="app">
          <p class="text-h6">
            {{ $t('conversationAnalysis.conversationTranscripts') }}
          </p>
          <div v-if="transcriptTableBody.length === 0">
            <p>{{ $t('conversationAnalysis.noTranscriptsAvailable') }}</p>
          </div>

          <div v-else>
            <p class="text-body-2" v-html="formattedCaptionText"></p>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(header, index) in transcriptTableHeaders"
                      align="center"
                      :key="index"
                      v-html="header"
                      nowrap=""
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in transcriptTableBody" :key="row.turnId">
                    <template v-if="row.isFirstInTopic">
                      <td
                        :rowspan="row.rowSpan"
                        class="text-left align-middle"
                        nowrap=""
                      >
                        {{ row.topic }}
                      </td>
                    </template>
                    <td align="center">{{ row.turnId }}</td>
                    <td align="center">{{ row.startTime }}</td>
                    <td align="center">{{ row.endTime }}</td>
                    <td align="left">
                      <v-icon small left>{{ row.icon }}</v-icon>
                      <span
                        :class="{
                          'font-italic': row.icon === 'mdi-robot',
                          'font-weight-bold': row.icon === 'mdi-account',
                        }"
                      >
                        {{ row.text }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { db, functions } from '@/plugins/firebase'
import { defineComponent } from '@vue/composition-api'
import { getDoc, doc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import type { Result } from '@/resources/result'
import {
  cefrStyleClass,
  roundToTwoDecimals,
} from '@/components/utils/commonUtils'
import type { CefrTableBody, TranscriptTableBody } from '@/resources/table'
import store from '@/store'
import CardHeader from '@/components/Common/CardHeader.vue'
import type { AudioFileResponse } from '@/resources/audio'

export default defineComponent({
  components: {
    CardHeader,
  },
  name: 'ConversationAnalysis',
  data() {
    return {
      loading: true,
      couponCode: '',
      dialogId: this.$route.params.dialogId,
      userId: '',
      cefrTableHeaders: [] as string[],
      cefrTableBody: [] as CefrTableBody[],
      transcriptTableHeaders: [] as string[],
      transcriptTableBody: [] as TranscriptTableBody[],
      error: false,
      results: null as Result | null,
      audio: undefined as string | undefined,
    }
  },
  watch: {
    '$i18n.locale'() {
      this.updateHeaders()
      if (this.results) {
        this.populateCefrTable(this.results)
      }
    },
  },
  created() {
    this.updateHeaders()
  },
  async mounted() {
    const customerId = store.state.customerId

    try {
      const results = await this.fetchCampaignDataByChannelName(this.dialogId)

      if (!results || results.userInfo.customerId !== customerId) {
        this.error = true
        return
      }

      this.couponCode = results.userInfo.couponCode
      this.userId = results.userInfo.uid
      this.results = results

      this.populateCefrTable(results)

      await this.fetchTranscripts()

      await this.getAudioTranscript()
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    formattedCaptionText() {
      const rawText = this.$t('conversationAnalysis.captionText').toString()
      const parts = rawText.split('*')
      return `
      <span class="font-italic">${parts[0]}</span>
      ${parts[1]}
      <span class="font-weight-bold">${parts[2]}</span>
      ${parts[3]}
    `
    },
  },
  methods: {
    cefrStyleClass,
    /**
     * Updates the table headers for the CEFR Table.
     */
    updateHeaders() {
      this.cefrTableHeaders = [
        this.$t('conversationAnalysis.cefrTableHeaders.overall') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.range') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.accuracy') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.phonology') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.fluency') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.coherence') as string,
        this.$t('conversationAnalysis.cefrTableHeaders.interaction') as string,
      ]
      this.transcriptTableHeaders = [
        this.$t('conversationAnalysis.transcriptTableHeaders.topic') as string,
        this.$t('conversationAnalysis.transcriptTableHeaders.turnId') as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.startTime'
        ) as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.endTime'
        ) as string,
        this.$t(
          'conversationAnalysis.transcriptTableHeaders.utteranceText'
        ) as string,
      ]
    },
    /**
     * Populates the CEFR (Common European Framework of Reference) table with scores.
     */
    populateCefrTable(scores: Result) {
      this.cefrTableBody = [
        {
          label: scores.fastResult.score.cefrOverall.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.cefrOverall.score
          ),
        },
        {
          label: scores.fastResult.score.range.cefrLevel,
          value: this.roundToTwoDecimals(scores.fastResult.score.range.score),
        },
        {
          label: scores.fastResult.score.accuracy.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.accuracy.score
          ),
        },
        {
          label: scores.fastResult.score.phonology.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.phonology.score
          ),
        },
        {
          label: scores.fastResult.score.fluency.cefrLevel,
          value: this.roundToTwoDecimals(scores.fastResult.score.fluency.score),
        },
        {
          label: scores.fastResult.score.coherence.cefrLevel,
          value: this.roundToTwoDecimals(
            scores.fastResult.score.coherence.score
          ),
        },
        {
          label:
            scores.fastResult.score.interaction.score === -1
              ? (this.$t('conversationAnalysis.unrated') as string)
              : scores.fastResult.score.interaction.cefrLevel,
          value:
            scores.fastResult.score.interaction.score !== -1
              ? this.roundToTwoDecimals(
                  scores.fastResult.score.interaction.score
                )
              : -1,
        },
      ]
    },
    /**
     * Fetches campaign data for a given channel name.
     * This function calls a cloud function 'getResultsByChannelName'
     * to retrieve the campaign data associated with the specified channel name.
     *
     * @param {string} dialogId - The unique identifier for the channel.
     * @returns {Promise<Result>} The campaign data as a Result object.
     */
    async fetchCampaignDataByChannelName(dialogId: string) {
      const getResultsByChannelName = httpsCallable(
        functions,
        'getResultsByChannelName'
      )
      const response = await getResultsByChannelName({
        channelName: dialogId,
      })

      return response.data as Result
    },
    /**
     * Fetches and processes the transcript data for a specific dialog.
     *
     * This function retrieves the transcript data from the Firebase Realtime Database
     * based on the `userId` and `dialogId` properties. It then processes the data
     * to create a structured array for rendering in a table. Each row in the table
     * corresponds to a line in the transcript, with additional processing to group
     * and merge rows by topic.
     */
    async fetchTranscripts() {
      const snapshot = await getDoc(doc(db, 'resultAnalyses', this.dialogId))
      if (snapshot.exists()) {
        const transcriptData = snapshot.data().transcripts

        const rows = transcriptData.trim().split('\n').slice(1)

        const topics = new Map<string, number>()
        let currentTopicIndex = 0

        this.transcriptTableBody = rows.map((row: string, index: number) => {
          const [startTime, endTime, topic, speaker, text] = row.split('\t')
          const icon = speaker === 'system' ? 'mdi-robot' : 'mdi-account'
          let isFirstInTopic = false
          let rowSpan = 1

          if (!topics.has(topic)) {
            currentTopicIndex++
            topics.set(topic, currentTopicIndex)
            isFirstInTopic = true

            rowSpan = rows.filter((r: string) =>
              r.includes(`\t${topic}\t`)
            ).length
          }

          return {
            topic: `Topic-${topics.get(topic)?.toString().padStart(2, '0')}`,
            turnId: (index + 1).toString().padStart(3, '0'),
            startTime,
            endTime,
            icon,
            text,
            isFirstInTopic,
            rowSpan,
          }
        })
      } else {
        console.error('No data available')
      }
    },
    roundToTwoDecimals,
    /**
     * Fetches audio transcript data from the server using a Firebase cloud function.
     * Converts the binary audio data into a Blob, creates a URL for the Blob,
     * and assigns it to be used in the audio player.
     */
    async getAudioTranscript() {
      const getConversationAudioFile = httpsCallable(
        functions,
        'getConversationAudioFile'
      )

      const response = (await getConversationAudioFile({
        dialogIdAsChannelName: this.dialogId,
      })) as AudioFileResponse

      try {
        const binaryData = response.data.audioData
        const base64Data = binaryData.split(',')[1] || binaryData

        const byteCharacters = window.atob(base64Data)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        const blob = new Blob([byteArray], {
          type: response.data.contentType || 'audio/mpeg',
        })
        this.audio = URL.createObjectURL(blob)
      } catch (error) {
        console.error('Error decoding base64 audio:', error)
      }
    },
  },
})
</script>
