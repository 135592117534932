<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow_half {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}
td {
  border-bottom: 1px solid #dbdbdb;
}

.result_title {
  max-height: 10px;
}

/* 追加 */
.v-input {
  font-size: 14px;
}
.select {
  margin-left: 30px;
  margin-bottom: 7px;
  height: 10px;
}

.campaign_code {
  text-decoration: underline;
}

.campaign_name {
  font-size: 18px;
  /* color: #1b2643; */
}
.DLbtn {
  width: 5rem;
}

.btn_results {
  background-color: transparent !important;
  box-shadow: none;
}
</style>

<template>
  <div>
    <v-card width="60vw" class="ma-10 card_shadow" v-show="!isShowComponent">
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container class="ml-5 pt-5">
          <v-row class="headline ml-n8 mt-n5">
            <v-col class="active_text">
              <p>{{ $t('home.campaigns') }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-10 mt-10 mb-n8 text-body-2">
            <p v-html="$t('home.campaignSearchScreen')"></p>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                class="v-input"
                v-model="filterExam"
                append-icon="mdi-magnify"
                :label="$t('home.searchQueryPlaceholder')"
                single-lin5
                hide-details
                @keypress.enter="searchCampaign"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="ma-2 mb-5 btn_shadow_half"
                color="#1b2643"
                dark
                @click="searchCampaign"
              >
                {{ $t('home.search') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mr-10 mb-4 text-body-2">
            <v-btn @click="isShowFilter = !isShowFilter" text
              >{{ $t('home.option.serchOption') }}
              <v-icon v-if="!isShowFilter">mdi-menu-right</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="isShowFilter">
            <v-col class="d-flex" cols="8">
              <v-select
                v-model="searchFor"
                :items="[
                  {
                    text: $t(
                      'home.stringSearchOptions.searchByCampaignCodeOrName'
                    ),
                    value: 'searchByCampaignCodeOrName',
                  },
                  {
                    text: $t('home.stringSearchOptions.searchByCampaignCode'),
                    value: 'searchByCampaignCode',
                  },
                  {
                    text: $t('home.stringSearchOptions.searchByCampaignName'),
                    value: 'searchByCampaignName',
                  },
                ]"
                :label="$t('home.option.searchStringTarget')"
                dense
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="isShowFilter">
            <v-col class="d-flex" cols="4">
              <v-select
                v-model="filterByPublicationPeriod"
                :items="[
                  {
                    text: $t('home.publicationPeriodFilterOption.noFilter'),
                    value: 'noFilter',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.beforePublicationPeriod'
                    ),
                    value: 'beforePublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.duringPublicationPeriod'
                    ),
                    value: 'duringPublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.afterPublicationPeriod'
                    ),
                    value: 'afterPublicationPeriod',
                  },
                  {
                    text: $t(
                      'home.publicationPeriodFilterOption.specifyPublicationPeriod'
                    ),
                    value: 'specifyPublicationPeriod',
                  },
                ]"
                :label="$t('home.option.publicationPeriodFilter')"
                dense
                @change=";(filterByOpenTime = 0), (filterByClosedTime = 0)"
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="ml-10"
            v-if="
              isShowFilter &&
              filterByPublicationPeriod ===
                $t(
                  'home.publicationPeriodFilterOption.specifyPublicationPeriod'
                )
            "
          >
            <v-col cols="5">
              <set-schedule
                :cardTitle="$t('home.timeSetting.startDate')"
                @changeDateTime="filterByOpenTime = $event"
              />
            </v-col>
            <v-col cols="5">
              <set-schedule
                :cardTitle="$t('home.timeSetting.endDate')"
                @changeDateTime="filterByClosedTime = $event"
              />
            </v-col>
          </v-row>
          <v-row class="mb-4" v-if="isShowFilter">
            <v-col class="d-flex" cols="4">
              <v-select
                v-model="filterByExamPeriod"
                :items="[
                  {
                    text: $t('home.examinationPeriodFilterOption.noFilter'),
                    value: 'noFilter',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.beforeInterviewPeriod'
                    ),
                    value: 'beforeInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.duringInterviewPeriod'
                    ),
                    value: 'duringInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.afterInterviewPeriod'
                    ),
                    value: 'afterInterviewPeriod',
                  },
                  {
                    text: $t(
                      'home.examinationPeriodFilterOption.specifyInterviewPeriod'
                    ),
                    value: 'specifyInterviewPeriod',
                  },
                ]"
                :label="$t('home.option.examinationPeriodFilter')"
                dense
                @change=";(filterByStartTime = 0), (filterByEndTime = 0)"
                class="select"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="ml-10"
            v-if="
              isShowFilter &&
              filterByExamPeriod ===
                $t('home.examinationPeriodFilterOption.specifyInterviewPeriod')
            "
          >
            <v-col cols="5">
              <set-schedule
                :cardTitle="$t('home.timeSetting.startDate')"
                @changeDateTime="filterByStartTime = $event"
              />
            </v-col>
            <v-col cols="5">
              <set-schedule
                :cardTitle="$t('home.timeSetting.endDate')"
                @changeDateTime="filterByEndTime = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <!-- テストデータの表示テーブル -->
    <v-card
      max-width="80vw"
      class="ma-10 card_shadow"
      v-show="!isShowComponent"
    >
      <template v-if="isLoadingCampaigns || isLoadingCoupons">
        <v-progress-linear indeterminate></v-progress-linear>
        <p class="w-full text-center primary--text text-caption">
          {{
            isLoadingCampaigns
              ? $t('home.progress.loadingCampaigns')
              : $t('home.progress.loadingCouponCounts')
          }}
        </p>
      </template>
      <v-form ref="form">
        <v-container class="pt-5">
          <v-row class="ml-5 mr-10 mt-3 mb-n8 text-body-2">
            <p>
              {{
                $t('home.displayCampaign', {
                  totalCampaigns: campaignNum,
                  displayedCampaigns: isShowCampaignNum,
                })
              }}
            </p>
          </v-row>
          <v-row
            v-if="searched && filterExamString"
            class="ml-10 mr-10 text-body-2"
          >
            {{ $t('home.searchquery') }}
            {{
              $t('home.filterCampaign', {
                filterCampaigns: filterExamString,
                searchByCampaignCodeOrName: $t(
                  `home.stringSearchOptions.${searchFor}`
                ),
              })
            }}
          </v-row>
          <v-row
            v-if="filterByPublicationPeriodString || filterByExamPeriodString"
            class="ml-10 mr-10 text-body-2"
          >
            {{ $t('home.filter') }}
            {{
              filterByPublicationPeriodString &&
              $t(
                `home.publicationPeriodFilterOption.${filterByPublicationPeriodString}`
              )
            }}
            {{
              filterByPublicationPeriodString && filterByExamPeriodString
                ? '&'
                : ''
            }}
            {{
              filterByExamPeriodString &&
              $t(
                `home.examinationPeriodFilterOption.${filterByExamPeriodString}`
              )
            }}
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="campaignData"
                :items-per-page="-1"
                :disable-pagination="true"
                :hide-default-footer="true"
                :options="options"
                multi-sort
              >
                <template v-slot:[`header.campaignCode`]="{ header }">
                  <th class="d-flex justify-center" v-html="header.text"></th>
                </template>
                <template v-slot:[`header.couponCounts`]="{ header }">
                  <th class="d-flex justify-center" v-html="header.text"></th>
                </template>
                <template v-slot:item="{ item }">
                  <tr v-show="item.isShow">
                    <td
                      class="text-center"
                      :style="{ color: item.isDynamic ? '#e2d06e' : '' }"
                    >
                      {{ item.campaignCode }}<br />（{{ item.campaignName }}）
                    </td>
                    <td
                      class="text-center"
                      :style="{ color: item.isDynamic ? '#e2d06e' : '' }"
                    >
                      {{ UnixTimeToString(item.openTime) }} ~
                      {{ UnixTimeToString(item.closedTime) }}
                    </td>
                    <td
                      class="text-center"
                      :style="{ color: item.isDynamic ? '#e2d06e' : '' }"
                    >
                      {{ UnixTimeToString(item.startTime) }} ~
                      {{ UnixTimeToString(item.endTime) }}
                    </td>
                    <td
                      class="text-center"
                      :style="{ color: item.isDynamic ? '#e2d06e' : '' }"
                    >
                      {{ item.couponNum }}&nbsp;&nbsp;
                      {{
                        item.totalTicketCount !== null
                          ? `( ${item.totalTicketCount} / ${
                              item.isUnlimited ? '-' : item.usedTicketCount
                            } )`
                          : '( ... )'
                      }}
                    </td>
                    <td
                      class="text-center"
                      :style="{ color: item.isDynamic ? '#e2d06e' : '' }"
                    >
                      <span v-if="item.isUnlimited">{{
                        $t('campaignSetting.interviewCountOption.unlimited')
                      }}</span>
                      <span v-else
                        >{{ item.limits }}
                        {{ $t('campaignSetting.time') }}</span
                      >
                    </td>
                    <td class="text-center">
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="btn_results"
                        :to="{
                          path: `/dashboard/campaign-results/${item.campaignCode}`,
                        }"
                      >
                        <v-icon small class="mr-2"
                          >mdi-file-search-outline</v-icon
                        >
                      </v-btn>
                    </td>
                    <td class="text-center">
                      <v-btn
                        :disabled="item.isProcessing"
                        class="btn_shadow_half"
                        color="#1b2643"
                        dark
                        @click="downloadBtnAction(item.campaignId)"
                        v-show="!item.isDownloading"
                      >
                        <v-icon color="white">mdi-folder-zip-outline</v-icon>
                        DL
                      </v-btn>
                      <v-btn
                        disabled
                        class="DLbtn"
                        color="white"
                        v-show="item.isDownloading"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          size="32"
                        ></v-progress-circular>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-form>
        <v-container>
          <snack-bar
            :snackbarAction="snackbarActionBtn"
            :snackbarText="snackbarTextField"
            :snackbarColor="snackbarColorField"
            @closeSnackbar="closeSnackbar"
          />
        </v-container>
      </v-form>
    </v-card>

    <set-exam
      v-show="isShowComponent"
      :isModify="true"
      :campaignId="editedItem.campaignId"
      :campaignCode="editedItem.campaignCode"
      :campaignName="editedItem.campaignName"
      :openTime="editedItem.openTime"
      :closedTime="editedItem.closedTime"
      :startTime="editedItem.startTime"
      :endTime="editedItem.endTime"
      :isUnlimited="editedItem.isUnlimited"
      :limits="String(editedItem.limits)"
      :isDynamicCampaign="editedItem.isDynamic"
      :couponNum="editedItem.couponNum"
      :scenarioDocId="editedItem.scenarioDocId"
      @closeComponent="resetComponent"
      @deleteItem="deleteItem"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
  doc,
} from 'firebase/firestore'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import store from '@/store'
// import LoadingCircle from '@/components/Dashboard/PageViewItems/LoadingCircleComponent.vue'
import SetExam from '@/components/Dashboard/PageViewItems/SetExamCardComponent.vue'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import SetSchedule from '@/components/Dashboard/PageViewItems/SetScheduleComponent.vue'
import moment from 'moment-timezone'
import type { Campaign } from '@/resources/campaign'
import type { TableHeader } from '@/resources/table'
import type { Result } from '@/resources/result'

type BaseCampaign = Pick<
  Campaign,
  | 'campaignCode'
  | 'customerCode'
  | 'customerId'
  | 'category'
  | 'isShow'
  | 'isUnlimited'
  | 'limits'
  | 'scenarioDocId'
  | 'systemInfo'
>

type CampaignType = BaseCampaign & {
  campaignId: string
  campaignName: string
  openTime: number
  closedTime: number
  startTime: number
  endTime: number
  couponNum: number
  totalTicketCount: number | null
  usedTicketCount: number | null
  isDownloading: boolean
  isProcessing: boolean
  isDynamic?: boolean
}

type ResultType = {
  userInfo: Result['userInfo'] & {
    campaignName?: string
  }
  dialogStatus: Result['dialogStatus']
  fastResult: Result['fastResult']
  systemInfo: Result['systemInfo']
}

type StatusSummaryType = {
  availableTicketCount: number
  totalTicketCount: number
  usedTicketCount: number
}

type TicketStatusSummaryType = {
  ticketStatusSummary: StatusSummaryType[]
}

type CampaignCodeToStatusSummaryType = {
  [campaignCode: string]: StatusSummaryType
}

export default defineComponent({
  name: 'HomeView',
  components: {
    // LoadingCircle,
    SetExam,
    SnackBar,
    SetSchedule,
  },
  data() {
    return {
      // 検索窓入力文字列の値指定
      filterExam: '',
      filterExamString: '',
      filterByPeriod: 'all',
      filterByPublicationPeriod: 'all',
      filterByExamPeriod: 'all',
      searchFor: 'all',
      isShowFilter: false,
      filterByPublicationPeriodString: '',
      filterByExamPeriodString: '',
      searchForString: '',
      searched: false,
      filterByOpenTime: 0,
      filterByClosedTime: 0,
      filterByStartTime: 0,
      filterByEndTime: 0,

      campaignInfo: false,

      campaignNum: 0,
      isShowCampaignNum: 0,

      isShowComponent: false,
      isLoadingCampaigns: false,
      isLoadingCoupons: false,

      // テスト情報の型指定
      campaignData: [] as CampaignType[],

      editedIndex: -1,
      editedItem: {} as CampaignType,
      snackbarActionBtn: false,
      snackbarTextField: '',
      snackbarColorField: '',
      resultList: [] as ResultType[],

      headers: [] as TableHeader[],
      options: {
        sortBy: ['openTime'],
        sortDesc: [false],
      },
    }
  },

  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
  },

  // 画面ロード時の認証とデータ取得
  async created() {
    this.initialize()
    this.updateHeaders()
  },

  async mounted() {
    await this.readData()
  },
  watch: {
    '$i18n.locale': function () {
      this.updateHeaders()
    },
  },
  methods: {
    initialize() {
      // propsの置き換え
      this.snackbarActionBtn = this.snackbarAction
      this.snackbarTextField = this.snackbarText
      this.snackbarColorField = this.snackbarColor
    },

    async resetComponent(emitedText: string) {
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = emitedText
      this.snackbarColorField = 'pink'
      await this.readData()
    },

    // テスト修正ボタン
    editItem(item: CampaignType) {
      this.editedIndex = this.campaignData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.isShowComponent = true
      // 以下画面遷移時のフィルタに関する不具合修正のための初期化
      // 急ピッチでの修正なのでもう少し良い形にしたい
      // this.filterExam = ''
      this.filterExamString = ''
      this.searchFor = ''
      this.searchForString = ''
      this.filterByExamPeriod = ''
      this.filterByExamPeriodString = ''
      this.filterByPublicationPeriod = ''
      this.filterByPublicationPeriodString = ''
    },

    // テスト削除ボタンコンポーネントで「削除」ボタンを押した際に呼び出される関数
    deleteItem() {
      this.$router.go(0)
      // 表示上からも削除
      this.campaignData.splice(this.editedIndex, 1)
      // 初期化
      this.editedIndex = -1
      this.editedItem = {
        customerCode: '',
        customerId: '',
        campaignId: '',
        campaignCode: '',
        campaignName: '',
        category: '',
        openTime: 0,
        closedTime: 0,
        startTime: 0,
        endTime: 0,
        couponNum: 0,
        totalTicketCount: 0,
        usedTicketCount: 0,
        isShow: true,
        isUnlimited: false,
        isDownloading: false,
        isProcessing: false,
        limits: 0,
        scenarioDocId: '',
        systemInfo: {
          Bacon: {
            systemVersion: '',
          },
        },
      }
      this.isShowComponent = false
      this.snackbarActionBtn = true
      this.snackbarTextField = '削除しました'
      this.snackbarColorField = 'pink'
      // 現状ではリロードするとスナックバーが一瞬で消えるので何かしらの対策が必要
    },

    // テスト期間の開始・終了日時入力用ポップアップにおける、「OK」ボタン押下時の日付入力確定機能
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.campaignData[this.editedIndex], this.editedItem)
      } else {
        this.campaignData.push(this.editedItem)
      }
    },

    searchCampaign() {
      this.isShowCampaignNum = 0 //検索の結果表示されるキャンペーンの数を初期化
      this.searched = true //検索を実行したか否か
      this.filterExamString = this.filterExam //検索語を格納（即時に反映されるのを防ぐために別の変数を使用）
      for (let i = 0; i < this.campaignData.length; i++) {
        var campaign = '' //文字列検索の対象となる文字列を格納するための変数
        this.campaignData[i]['isShow'] = true
        // 文字列検索

        if (this.searchFor === 'searchByCampaignCode') {
          campaign = this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCode') as string
        } else if (this.searchFor === 'searchByCampaignName') {
          campaign = this.campaignData[i]['campaignName']
          this.searchForString = this.$t('home.campaignName') as string
        } else {
          campaign =
            this.campaignData[i]['campaignName'] +
            this.campaignData[i]['campaignCode']
          this.searchForString = this.$t('home.campaignCodeOrName') as string
        }
        if (campaign.indexOf(this.filterExamString) === -1) {
          this.campaignData[i]['isShow'] = false
        }

        // 現在時刻の取得
        var presentTime = new Date(moment().tz('Asia/Tokyo').format()).getTime()

        // 公開期間フィルタ
        this.filterByPublicationPeriodString = this.filterByPublicationPeriod

        if (this.filterByPublicationPeriod === 'beforePublicationPeriod') {
          if (presentTime > this.campaignData[i]['openTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'duringPublicationPeriod'
        ) {
          if (
            presentTime < this.campaignData[i]['openTime'] ||
            presentTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'afterPublicationPeriod'
        ) {
          if (presentTime < this.campaignData[i]['closedTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByPublicationPeriod === 'specifyPublicationPeriod' &&
          (this.filterByOpenTime !== 0 || this.filterByClosedTime !== 0)
        ) {
          if (
            this.filterByClosedTime < this.campaignData[i]['openTime'] ||
            this.filterByOpenTime > this.campaignData[i]['closedTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByPublicationPeriodString = `${this.$t(
            'home.publicationPeriodFilterOption.specifyPublicationPeriod'
          )}（${this.UnixTimeToString(
            this.filterByOpenTime
          )}) 〜 (${this.UnixTimeToString(this.filterByClosedTime)})`
        } else {
          this.filterByPublicationPeriodString = ''
        }

        // 受験期間フィルタ
        this.filterByExamPeriodString = this.filterByExamPeriod
        if (this.filterByExamPeriod === 'beforeInterviewPeriod') {
          if (presentTime > this.campaignData[i]['startTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'duringInterviewPeriod') {
          if (
            presentTime < this.campaignData[i]['startTime'] ||
            presentTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (this.filterByExamPeriod === 'afterInterviewPeriod') {
          if (presentTime < this.campaignData[i]['endTime']) {
            this.campaignData[i]['isShow'] = false
          }
        } else if (
          this.filterByExamPeriod === 'specifyInterviewPeriod' &&
          (this.filterByStartTime !== 0 || this.filterByEndTime !== 0)
        ) {
          if (
            this.filterByEndTime < this.campaignData[i]['startTime'] ||
            this.filterByStartTime > this.campaignData[i]['endTime']
          ) {
            this.campaignData[i]['isShow'] = false
          }
          this.filterByExamPeriodString = `${this.$t(
            'home.examinationPeriodFilterOption.specifyInterviewPeriod'
          )} (${this.UnixTimeToString(
            this.filterByStartTime
          )}) 〜 (${this.UnixTimeToString(this.filterByEndTime)})`
        } else {
          this.filterByExamPeriodString = ''
        }

        // 表示数のカウント
        if (this.campaignData[i]['isShow']) {
          this.isShowCampaignNum++
        }
      }
    },

    // DBからの読み込み
    async readData() {
      this.campaignData = []
      this.campaignNum = 0
      this.isShowCampaignNum = 0
      const campaignsRef = collection(db, 'campaigns')
      const customerId = store.state.customerId

      this.isLoadingCampaigns = true
      this.isLoadingCoupons = true

      const campaignsData = await getDocs(
        query(campaignsRef, where('customerId', '==', customerId))
      )
      for (const document of campaignsData.docs) {
        const campaignId = document.id
        if (document.data().couponCount === undefined) {
          var couponNum = (
            await getDocs(collection(db, 'campaigns', campaignId, 'coupons'))
          ).size
          await updateDoc(doc(db, 'campaigns', campaignId), {
            couponCount: couponNum,
          })
        } else {
          // eslint-disable-next-line no-redeclare
          var couponNum = document.data().couponCount as number
        }

        if (!document.data().isDeleted) {
          this.campaignNum++
          this.isShowCampaignNum++

          this.campaignData.push({
            customerId: document.data().customerId,
            customerCode: document.data().customerCode,
            campaignId: campaignId,
            campaignCode: document.data().campaignCode,
            campaignName: document.data().name,
            category: document.data().category,
            openTime: this.Utc2UnixTime(document.data().openAt),
            closedTime: this.Utc2UnixTime(document.data().closedAt),
            startTime: this.Utc2UnixTime(document.data().startAt),
            endTime: this.Utc2UnixTime(document.data().endAt),
            couponNum: couponNum,
            totalTicketCount: null,
            usedTicketCount: null,
            isShow: true,
            isUnlimited: document.data().isUnlimited,
            limits: document.data().limits,
            systemInfo: document.data().systemInfo,
            isDownloading: false,
            isProcessing: false,
            scenarioDocId: document.data().scenarioDocId,
            isDynamic: document.data().isDynamic,
          })
        }
        // console.log(document.id)
      }
      this.isLoadingCampaigns = false

      // cloud functionを使ってクーポン登録者数と受験終了者数を取得しにいく
      let campaignCodeToStatusSummaryType =
        {} as CampaignCodeToStatusSummaryType
      try {
        const getTicketStatusSummary = httpsCallable(
          functions,
          'getTicketStatusSummary'
        )
        const inputData = {
          campaignCodes: campaignsData.docs.map(
            (doc) => doc.data().campaignCode
          ),
        }
        const result = await getTicketStatusSummary(inputData)
        const data = result.data as TicketStatusSummaryType
        for (let campaignCode in data.ticketStatusSummary) {
          campaignCodeToStatusSummaryType[campaignCode] =
            data.ticketStatusSummary[campaignCode]
        }
        for (let i = 0; i < this.campaignData.length; i++) {
          const campaign = this.campaignData[i]
          const campaignCode = campaign.campaignCode
          campaign.totalTicketCount =
            campaignCodeToStatusSummaryType[campaignCode].totalTicketCount
          campaign.usedTicketCount =
            campaignCodeToStatusSummaryType[campaignCode].usedTicketCount
        }
      } catch (error: any) {
        const code = error.code
        // const message = error.message
        // const details = error.details
        console.log('Cloud Function err code: ', code)
        // console.log('Cloud Function err message: ', message)
        // console.log('Cloud Function err details: ', details)
      } finally {
        this.isLoadingCoupons = false
      }
    },

    // UnixTime変換
    Utc2UnixTime(dateString: string) {
      // timezoneをZ(UTC)と指定してunixTimeを取得
      return new Date(dateString).getTime()
    },

    UnixTimeToString(UnixTime: number) {
      return moment(UnixTime).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    },

    async getResultsByCampaign(campaignId: string) {
      // cloud functionsとの連携
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          const data = result.data
          // console.log(data)
          return data
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    async getResultsCountByCampaignId(campaignId: string) {
      //resultListのリセット
      this.resultList = [] as ResultType[]
      const getResultsByCampaignIdForManagers = httpsCallable(
        functions,
        'getResultsByCampaignIdForManagers'
      )
      const inputData = {
        campaignId: campaignId,
      }
      return await getResultsByCampaignIdForManagers(inputData)
        .then((result) => {
          this.resultList = result.data as ResultType[]

          // 当該examのユニーク受験者数をカウントしてリターン
          // 再受験は一切考慮しない（一回でも受験していれば受験済にカウント：再受験を詳細に扱う場合はdocument/propertyやfunctionの追加が実行性能的に妥当と考えられる
          let studentIds = [] as string[]
          let resultsCount = 0
          this.resultList.forEach((target) => {
            if (target.userInfo.couponCode) {
              if (!studentIds.includes(target.userInfo.couponCode)) {
                resultsCount++
                studentIds.push(target.userInfo.couponCode)
              }
            }
          })
          return resultsCount
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    // DLボタン押下時の処理
    async downloadBtnAction(campaignId: string) {
      for await (const item of this.campaignData) {
        if (item.campaignId === campaignId) {
          if (item.isProcessing) {
            break
          } else {
            item.isDownloading = true
            item.isProcessing = true
            await this.downloadData(campaignId).then(() => {
              setTimeout(() => {
                item.isDownloading = false
                item.isProcessing = false
              }, 500)
            })
          }
          break
        }
      }
    },
    // csv変換を加えたDL機能
    async downloadData(campaignId: string) {
      // console.log('running data export...')
      let jsonData = {} as ResultType[]
      jsonData = await this.downloadCampaignData(campaignId)
      if (jsonData) {
        this.downloadForCsv(jsonData)
      } else {
        this.downloadForNullCsv()
      }
    },

    async downloadCampaignData(campaignId: string) {
      // console.log('download campaignId:', campaignId)
      const result = (await this.getResultsByCampaign(
        campaignId
      )) as ResultType[]
      // console.log('JSON data: ', result)
      return result
    },

    downloadForCsv(jsonData: ResultType[]) {
      const env = process.env.NODE_ENV
      let langxWebAppUrl =
        env === 'development' && window.location.hostname === 'localhost'
          ? 'http://localhost:5173'
          : env === 'development' && window.location.hostname !== 'localhost'
          ? 'https://develop-langx-learner-webapp-2aru7nyvta8n.web.app'
          : env === 'staging'
          ? 'https://staging-langx-learner-webapp-t2qbajc92vch.web.app'
          : env === 'production'
          ? 'https://speaking.langx.ai'
          : 'http://localhost:5173'

      var dataCsv =
        'CampaignCode,CouponCode,Completion time,CEFR Level,CEFR Score,Accuracy Level,Accuracy Score,Coherence Level,Coherence Score,Fluency Level,Fluency Score,Interaction Level,Interaction Score,Phonology Level,Phonology Score,Range Level,Range Score,channelName,Link to shared report page' //ここの内容は何が必要か要確認
      jsonData.forEach((data) => {
        const interactionLevel =
          data.fastResult.score.interaction.score === -1
            ? this.$t('conversationAnalysis.unrated')
            : data.fastResult.score.interaction.cefrLevel
        const interactionScore =
          data.fastResult.score.interaction.score === -1
            ? ''
            : data.fastResult.score.interaction.score

        let csvCampaignCode = data.userInfo.campaignCode
        // let csvCampaignName = data.userInfo.campaignName
        let csvCouponCode = data.userInfo.couponCode
        // eslint-disable-next-line
        let csvEndAt = data.dialogStatus.assessmentCompletedAt
        let csvEndAtJST = this.UnixTimeToString(this.Utc2UnixTime(csvEndAt))
        let csvCefrLevel = data.fastResult.score.cefrOverall.cefrLevel
        let csvCefrScore = data.fastResult.score.cefrOverall.score
        let csvAccuracyLevel = data.fastResult.score.accuracy.cefrLevel
        let csvAccuracyScore = data.fastResult.score.accuracy.score
        let csvCoherenceLevel = data.fastResult.score.coherence.cefrLevel
        let csvCoherenceScore = data.fastResult.score.coherence.score
        let csvFluencyLevel = data.fastResult.score.fluency.cefrLevel
        let csvFluencyScore = data.fastResult.score.fluency.score
        let csvInteractionLevel = interactionLevel
        let csvInteractionScore = interactionScore
        let csvPhonologyLevel = data.fastResult.score.phonology.cefrLevel
        let csvPhonologyScore = data.fastResult.score.phonology.score
        let csvRangeLevel = data.fastResult.score.range.cefrLevel
        let csvRangeScore = data.fastResult.score.range.score
        let csvChannelName = data.dialogStatus.channelName
        let sharedReportLink = `${langxWebAppUrl}/shared-report/${data.dialogStatus.channelName}`
        var dataLine =
          '\n' +
          csvCampaignCode +
          ',' +
          // csvCampaignName +
          // ',' +
          csvCouponCode +
          ',' +
          csvEndAtJST +
          ',' +
          csvCefrLevel +
          ',' +
          csvCefrScore +
          ',' +
          csvAccuracyLevel +
          ',' +
          csvAccuracyScore +
          ',' +
          csvCoherenceLevel +
          ',' +
          csvCoherenceScore +
          ',' +
          csvFluencyLevel +
          ',' +
          csvFluencyScore +
          ',' +
          csvInteractionLevel +
          ',' +
          csvInteractionScore +
          ',' +
          csvPhonologyLevel +
          ',' +
          csvPhonologyScore +
          ',' +
          csvRangeLevel +
          ',' +
          csvRangeScore +
          ',' +
          csvChannelName +
          ',' +
          sharedReportLink
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dataCsv += dataLine
      })
      const blob = new Blob([`\uFEFF${dataCsv}`], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      // eslint-disable-next-line
      // link.download = 'exportData' + '-' + sectionId.substring(0, 4) + '-' +displayExamId + now.getTime() + '.csv'
      link.download = 'exportData_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },

    downloadForNullCsv() {
      var str = 'No Results'
      const blob = new Blob([str], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      // eslint-disable-next-line
      // link.download = 'exportData' + '-' + sectionId.substring(0, 4) + '-' +displayExamId + now.getTime() + '.csv'
      link.download = 'exportData_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },

    closeSnackbar() {
      this.isSnackbarActionBtn = false
    },

    getHeaders(): TableHeader[] {
      return [
        {
          text: this.$t('home.tableHeader.campaignCodeOrName') as string,
          value: 'campaignCode',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.publicPeriod') as string,
          value: 'openTime',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.examPeriod') as string,
          value: 'startTime',
          align: 'center',
          sortable: true,
        },
        {
          text: this.$t('home.tableHeader.couponCounts') as string,
          value: 'couponCounts',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.interviewCountPerCoupon') as string,
          value: 'interviewCountPerCoupon',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.edit') as string,
          value: 'edit',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.showResults') as string,
          value: 'showResults',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('home.tableHeader.download') as string,
          value: 'download',
          align: 'center',
          sortable: false,
        },
      ]
    },

    updateHeaders() {
      this.headers = this.getHeaders()
    },
  },
})
</script>
