<template>
  <v-app>
    <side-bar />
    <v-main>
      <router-view />
    </v-main>
    <bottom-bar />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import SideBar from '@/components/Dashboard/SideBarComponent.vue'
import BottomBar from '@/components/Common/BottomBarComponent.vue'

export default defineComponent({
  name: 'DashboardLayout',
  components: {
    SideBar,
    BottomBar,
  },
  setup() {
    return {}
  },
})
</script>
