<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}
</style>

<template>
  <v-card max-width="484px" class="ma-10 card_shadow">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container class="ml-5 pt-5">
        <v-row>
          <h2>{{ $t('securitySettings.currentPasswordPrompt') }}</h2>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-model="password"
              :label="$t('common.password')"
              persistent-placeholder
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="#1867C0" @click="onClick" class="white--text">
          {{ $t('securitySettings.next') }}
        </v-btn>
      </v-container>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { auth } from '@/plugins/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { getAuth } from '@firebase/auth'

export default defineComponent({
  name: 'SecurityPass',
  setup() {
    const auth = getAuth()
    const user = auth.currentUser
    let email = ''
    if (user && user.email) {
      email = user.email
    }
    return {
      email,
    }
  },
  data() {
    return {
      password: '',
      showPassword: false,
    }
  },
  methods: {
    onClick() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        //.then((user) => {
        .then(() => {
          //  console.log('認証に成功しました: ', user.user.uid)
          this.$router.push({
            name: 'Security',
          })
        })
        .catch((error) => {
          console.log('error code:', error.code)
          // サインイン失敗時の処理追加必要
        })
    },
  },
})
</script>
