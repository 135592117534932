<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}
.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}
</style>
<template>
  <v-card width="60vw" class="ma-10 card_shadow">
    <v-container class="pt-5">
      <v-row class="headline mt-n5">
        <v-col class="active_text">
          <p>{{ $t('operatingGuide.manuals') }}</p>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-8">
        <p>{{ $t('operatingGuide.currentlyPreparingMessage') }}</p>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GuideView',
  setup() {
    return {}
  },
})
</script>
