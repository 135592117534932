<style scoped>
.full-height-width-card {
  min-height: 100vh;
  width: 100vw;
}
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}
.title {
  font: normal normal normal 24px/36px Roboto;
}
.filled_noline >>> .v-input__slot::before {
  border-style: none !important;
}
.under-line {
  text-decoration: underline;
}
</style>

<template>
  <v-card
    color="transparent"
    flat
    class="full-height-width-card align-center justify-center"
  >
    <v-row justify="center" v-show="showMain">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center">
                <p class="title">{{ $t('common.langxConsole') }}</p>
              </v-row>
              <v-row justify="center" class="mt-0">
                <p class="title">{{ $t('signIn.signIn') }}</p>
              </v-row>
              <v-row v-if="isSignInError" justify="center">
                <v-col cols="10">
                  <v-alert icon="mdi-alert-circle" type="error">
                    {{ $t('signIn.incorrectUsernameOrPassword') }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-7">
                <v-col cols="10">
                  <v-text-field
                    v-model="email"
                    :label="$t('common.email')"
                    persistent-placeholder
                    outlined
                    required
                    @focus="clearSignInError"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-col cols="10">
                  <v-text-field
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    v-model="password"
                    :label="$t('common.password')"
                    filled
                    dense
                    required
                    class="filled_noline"
                    @focus="clearSignInError"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn
                  :disabled="inputed"
                  color="#1b2643"
                  class="mb-10 white--text"
                  @click="onClickSignIn"
                >
                  {{ $t('signIn.signIn') }}
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="ma-4 under-line"
                  @click="
                    clearSignInError($event)
                    clearInputText($event)
                    showMain = !showMain
                  "
                >
                  {{ $t('signIn.forgotPasswordButton') }}
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="ma-4 under-line"
                  @click="goToSignUp"
                >
                  {{ $t('signIn.signUpHereButton') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" v-show="!showMain">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center">
                <p class="title">{{ $t('common.langxConsole') }}</p>
              </v-row>
              <v-row justify="center" class="mt-0">
                <p class="title">{{ $t('signIn.passwordResetEmailSend') }}</p>
              </v-row>
              <v-row justify="center" class="mt-7">
                <v-col cols="10">
                  <v-text-field
                    v-model="email"
                    :label="$t('common.email')"
                    persistent-placeholder
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn
                  :disabled="inputed"
                  color="#1b2643"
                  class="mb-10 white--text"
                  @click="
                    onClickForgetPass($event)
                    clearInputText($event)
                  "
                >
                  {{ $t('signIn.sendEmail') }}
                </v-btn>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="ma-4 under-line"
                  @click="
                    clearInputText($event)
                    showMain = !showMain
                  "
                >
                  {{ $t('signIn.returnToSignInScreen') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <language-switching color="black">
        <template v-slot:activator="{ attrs, on }">
          <v-btn large outlined v-bind="attrs" v-on="on">
            <v-icon left>mdi-translate</v-icon>
            <v-list-item-title>{{
              $t('common.languageSwitching')
            }}</v-list-item-title>
          </v-btn>
        </template>
      </language-switching>
    </div>

    <v-form>
      <v-container>
        <snack-bar
          :snackbarAction="isSnackbarAction"
          :snackbarText="snackbarTextInput"
          :snackbarColor="snackbarColorInput"
          @closeSnackbar="closeSnackbar"
        />
      </v-container>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { auth } from '@/plugins/firebase'
import {
  signInWithEmailAndPassword,
  signOut,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth'
// import store from '@/store'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import LanguageSwitching from '@/components/Common/LanguageSwitchingComponent.vue'

export default defineComponent({
  name: 'SignInView',
  components: {
    SnackBar,
    LanguageSwitching,
  },
  data() {
    return {
      valid: true,
      inputed: false,
      vertical: true,
      email: '',
      password: '',
      showPassword: false,
      showMain: true,
      isSnackbarAction: false,
      snackbarColorInput: 'pink',
      isSignInError: false,
      snackbarTextInput: '',
    }
  },

  props: {
    snackbarAction: { type: Boolean, default: false },
    snackbarText: { type: String, default: '' },
    snackbarColor: { type: String, default: '' },
  },

  created() {
    // サインイン画面を開くと強制サインアウトおよびストレージのリセット
    signOut(auth).then(() => {
      this.$store.commit('reset', this.$store.state)
    })
  },

  mounted() {
    // マウント完了後にprops読み込み -> データの変更を検知させる(createdだとSnackBarが変更を検知しない)
    this.isSnackbarAction = this.snackbarAction
    this.snackbarColorInput = this.snackbarColor
    this.snackbarTextInput = this.snackbarText
  },

  methods: {
    onClickSignIn() {
      // サインイン
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(async (user) => {
          const idTokenResult = await user.user.getIdTokenResult(true)
          // console.log('基本認証成功: ', idTokenResult)
          // auth認証結果のトークン内のカスタムクレーム情報からroleを参照しrole=manager, email_verified=true, customerId!=falseの場合のみサインイン可能
          if (!idTokenResult.claims['email_verified']) {
            // console.log('詳細認証失敗: Emailのユーザ側の認証が未完了です')
            this.isSignInError = true
            return
          } else if (idTokenResult.claims['role'] !== 'manager') {
            // console.log('詳細認証失敗: マネージャーではないユーザです')
            this.isSignInError = true
            return
          } else if (!idTokenResult.claims['customerId']) {
            // 特に開発時、customerIdがセットされていないユーザでサインインを試みた場合などを想定
            // console.log('詳細認証失敗: customerIdが存在しないユーザです')
            this.isSignInError = true
          } else {
            // console.log('詳細認証成功: あなたは正しいマネージャーです！')
            // 以下のthis.$store.commitの箇所は必要最低限のものまで削減する余地あり（頻度の低い変数はauthから直接参照など）
            this.$store.commit('setIdToken', idTokenResult)
            this.$store.commit('setUid', user.user.uid)
            this.$store.commit(
              'setCustomerId',
              idTokenResult.claims['customerId']
            )
            this.$store.commit('setEmail', user.user.email)

            // console.log(
            //   'ストアにセットされたcustomerId: ',
            //   store.state.customerId
            // )
            // console.log('ストアにセットされたemail: ', store.state.email)

            //サインイン後、Homeへ移動
            const redirectPath = this.$route.query.redirect
              ? decodeURIComponent(this.$route.query.redirect as string)
              : '/dashboard/home'

            const resolvedRoute = this.$router.resolve(redirectPath)
            if (
              resolvedRoute.resolved.matched.length === 0 ||
              resolvedRoute.route.path !== redirectPath
            ) {
              this.$router.push('/dashboard/home')
            } else {
              this.$router.push(redirectPath)
            }
          }
        })
        .catch((error) => {
          // リリース前にconsole表示は削除
          console.log('error code:', error.code)
          // const errorCode = error.code
          // const errorMessage = error.message
          // console.log(
          //   'Firebase Authenticationからエラーが返されました: ',
          //   errorCode,
          //   ', ',
          //   errorMessage
          // )
          this.isSignInError = true
        })
    },
    async onClickForgetPass() {
      const auth = getAuth()
      const url = `${window.location.origin}/verified`
      await sendPasswordResetEmail(auth, this.email, { url })
        .then(() => {
          // console.log('再認証メールの送信に成功しました: ', this.email)
          this.isSnackbarAction = true
          this.snackbarColorInput = 'pink'
          this.snackbarTextInput = 'パスワード再設定メールを送信しました。'
          this.showMain = !this.showMain
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },
    clearSignInError() {
      this.isSignInError = false
    },
    clearInputText() {
      this.email = ''
      this.password = ''
    },
    goToSignUp() {
      this.$router.push({ name: 'Sign Up' })
    },
    // snackbarコンポーネントのcloseSnackbar関数
    closeSnackbar() {
      this.isSnackbarAction = false
    },
  },
})
</script>
