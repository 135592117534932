<style scoped>
.full-height-width-card {
  min-height: 100vh;
  width: 100vw;
}
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}
.title {
  font: normal normal normal 24px/36px Roboto;
}
.filled_noline >>> .v-input__slot::before {
  border-style: none !important;
}
.under-line {
  text-decoration: underline;
}
</style>

<template>
  <v-card
    color="transparent"
    flat
    class="full-height-width-card align-center justify-center"
  >
    <v-row justify="center">
      <v-col cols="10">
        <v-card class="ma-10 card_shadow align-center justify-center">
          <v-form ref="form" lazy-validation>
            <v-container class="pa-13">
              <v-row justify="center">
                <p class="title">{{ $t('common.langxConsole') }}</p>
              </v-row>
              <v-row justify="center" class="mt-7">
                <p>{{ $t('signUp.emailApprovalCompletedMessage') }}</p>
              </v-row>
              <v-row justify="center" class="mt-n7">
                <v-btn
                  text
                  color="blue"
                  class="mt-4 under-line"
                  @click="goToSignIn"
                >
                  {{ $t('signUp.signInHereButton') }}
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth'
import { auth } from '@/plugins/firebase'

export default defineComponent({
  name: 'VerifiedView',
  data() {
    return {}
  },

  mounted() {
    const mode = this.$route.query.mode as string
    const oobCode = this.$route.query.oobCode as string
    // console.log(this.$route.query.mode)
    // console.log(this.$route.query.oobCode)
    if (auth && oobCode) {
      mode === 'verifyEmail'
        ? applyActionCode(auth, oobCode)
        : verifyPasswordResetCode(auth, oobCode)
    }
  },

  methods: {
    goToSignIn() {
      this.$router.push({ name: 'Sign In' })
    },
  },
})
</script>
