<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.btn_shadow_half {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}

td {
  border-bottom: 1px solid #dbdbdb;
}

.color-red {
  color: red;
}
</style>

<template>
  <div>
    <v-card width="60vw" class="ma-10 card_shadow">
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container class="ml-5 pt-5">
          <v-row class="headline ml-n8 mt-n5">
            <v-col class="active_text">
              <p>{{ $t('interviewDataSearch.searchInterviewData') }}</p>
            </v-col>
          </v-row>

          <!-- 「テストを絞り込む」画面 -->
          <v-row v-show="isFilterTest" class="ml-1 mt-10 mb-n8">
            <p
              class="text-body-2"
              v-html="
                $t('interviewDataSearch.searchExamineeResultByCouponCode')
              "
            ></p>
          </v-row>
          <v-row v-show="isFilterTest" class="mb-2">
            <v-col cols="8">
              <v-text-field
                v-model="filterCouponCode"
                append-icon="mdi-magnify"
                :label="$t('interviewDataSearch.couponCode')"
                single-line
                hide-details
                @keypress.enter="searchCoupon(filterCouponCode)"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="ma-2 mb-5 btn_shadow_half"
                color="#1b2643"
                dark
                @click="searchCoupon(filterCouponCode)"
              >
                {{ $t('interviewDataSearch.search') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <loading-circle
      :isLoading="isLoading"
      :text="loadingText"
      :progressValue="couponProgressValue"
    />

    <!-- 「検索結果一覧」画面 -->
    <v-card max-width="90vw" class="ma-10 card_shadow">
      <v-form ref="form" v-show="searchedCoupons.length !== 0">
        <v-container class="mt-5">
          <v-row class="ml-5 mr-5">
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>{{ $t('interviewDataSearch.couponCode') }}</th>
                      <th
                        class="text-left"
                        v-html="$t('interviewDataSearch.campaignCodeOrName')"
                      ></th>
                      <th
                        class="text-left"
                        v-html="$t('interviewDataSearch.learnersNameOrmail')"
                      ></th>
                      <th v-html="$t('interviewDataSearch.cefrLevel')"></th>
                      <!-- <th>受験結果</th> -->
                      <th
                        class="text-left"
                        v-html="$t('interviewDataSearch.interviewCounts')"
                      ></th>
                      <th
                        class="text-left"
                        v-html="$t('interviewDataSearch.allowRetakes')"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="searchedCoupon in searchedCoupons"
                      :key="searchedCoupon.id"
                    >
                      <td v-show="!searchedCoupon.isDeleted">
                        {{ searchedCoupon.couponCode }}
                      </td>
                      <td v-show="!searchedCoupon.isDeleted">
                        {{ searchedCoupon.campaignCode }}<br />
                        ({{ searchedCoupon.campaignName }})
                      </td>
                      <td v-show="!searchedCoupon.isDeleted">
                        {{ searchedCoupon.learnerName }}<br />
                        {{ searchedCoupon.learnerMail }}
                      </td>
                      <td v-show="!searchedCoupon.isDeleted">
                        {{ searchedCoupon.cefrLevel }}<br />
                        ({{ searchedCoupon.cefrScore }})
                      </td>
                      <td v-show="!searchedCoupon.isDeleted">
                        <span v-if="!searchedCoupon.ticket.countIsUndefined">
                          {{ searchedCoupon.ticket.count }}
                        </span>
                        <span v-if="searchedCoupon.ticket.countIsUndefined">
                          -
                        </span>
                        <span>/</span>
                        <span>{{ searchedCoupon.limits }}</span>
                      </td>
                      <td>
                        <template>
                          <v-btn
                            class="mr-4 white--text btn_shadow_half"
                            color="#1b2643"
                            @click="resumeBtn(searchedCoupon)"
                            :disabled="
                              searchedCoupon.isDeleted ||
                              searchedCoupon.ticket.count !==
                                Number(searchedCoupon.limits)
                            "
                          >
                            {{ $t('interviewDataSearch.retakes') }}
                          </v-btn>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-dialog v-model="dialogConfirm" width="600px">
              <v-card class="pt-5">
                <v-card-title class="justify-center">{{
                  $t('interviewDataSearch.dialog.allowRetakeExamPrompt')
                }}</v-card-title>
                <v-card-text>
                  {{ $t('interviewDataSearch.dialog.couponCode')
                  }}{{ editeingItem.couponCode }}<br />
                  {{ $t('interviewDataSearch.dialog.campaignCode') }}
                  {{ editeingItem.campaignCode }}<br />
                  {{ $t('interviewDataSearch.dialog.campaignNam') }}
                  {{ editeingItem.campaignName }}<br />
                  {{ $t('interviewDataSearch.dialog.learnersName') }}
                  {{ editeingItem.learnerName }}<br />
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="#1b2643" text @click="dialogConfirm = false">{{
                    $t('interviewDataSearch.dialog.cancle')
                  }}</v-btn>
                  <v-btn color="#1b2643" text @click="resume()">{{
                    $t('interviewDataSearch.retakes')
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <snack-bar
      :snackbarAction="isSnackbarAction"
      :snackbarText="snackbarText"
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import store from '@/store'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import LoadingCircle from '@/components/Dashboard/PageViewItems/LoadingCircleComponent.vue'

type CampaignType = {
  documentId: string
  customerCode: string
  customerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  isDeleted: boolean
  isUnlimited: boolean
  limits: number
}

type TicketInfoType = {
  documentId: string
  confirmedAt: string
  countIsUndefined: boolean
  count: number
  couponCode: string
  isAvailable: boolean
  password: string
  punchedAt: string
}

type SearchedCouponsType = {
  id: number
  learnerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  ticket: TicketInfoType
  isDeleted: boolean
  isUnlimited: boolean
  limits: string
  couponCode: string | null
  learnerMail: string
  learnerName: string
  cefrScore: number
  cefrLevel: string
}

type ResultType = {
  userInfo: {
    uid: string
    customerId: string | null
    customerCode: string | null
    campaignId: string | null
    campaignCode: string | null
    campaignName: string | null
    couponCode: string | null
    password: string | null
    email: string | null
    language: 'en' | 'ja'
  }
  dataURL: string
  dialogStatus: {
    dialogId: string
    dialogStartedAt: string
    dialogEndedAt: string
    assessmentCompletedAt: string
    dialogType: string
    isAssessed: boolean
    isInterviewed: boolean
    scenarioId: string
  }
  fastResult: {
    feedback: {
      strength: string
      weakness: string
    }
    levelDescription: string
    score: {
      cefrOverall: {
        cefrLevel: string
        score: number
        description: string
      }
      accuracy: {
        cefrLevel: string
        score: number
        description: string
      }
      coherence: {
        cefrLevel: string
        score: number
        description: string
      }
      fluency: {
        cefrLevel: string
        score: number
        description: string
      }
      interaction: {
        cefrLevel: string
        score: number
        description: string
      }
      phonology: {
        cefrLevel: string
        score: number
        description: string
      }
      range: {
        cefrLevel: string
        score: number
        description: string
      }
    }
  }
  systemInfo: {
    [moduleName: string]: {
      version: string
    }
  }
}

export default defineComponent({
  name: 'LearnersView',
  components: {
    SnackBar,
    LoadingCircle,
  },
  data() {
    return {
      isFilterTest: true,
      couponProgressValue: 0,

      filterCouponCode: '',
      dialogConfirm: false,

      editingIndex: -1,
      editeingItem: {} as SearchedCouponsType,
      campaigns: [] as CampaignType[],
      searchedCoupons: [] as SearchedCouponsType[],

      isSnackbarAction: false,
      snackbarText: this.$t(
        'interviewDataSearch.learnerDataUpdatedMessage'
      ) as string,
      snackbarColor: 'pink',

      isLoading: false,
      loadingText: this.$t(
        'interviewDataSearch.progress.longOperationWaitMessage'
      ) as string,
    }
  },

  async mounted() {
    // this.readData()
    this.getCampaigns()
  },

  methods: {
    // snackbarコンポーネントのcloseSnackbar関数
    closeSnackbar() {
      this.isSnackbarAction = false
    },

    async getCampaigns() {
      // customerIdと一致するcampaignsを取得
      const customerId = store.state.customerId
      const campaignsRef = collection(db, 'campaigns')

      getDocs(query(campaignsRef, where('customerId', '==', customerId))).then(
        (campaignData) => {
          campaignData.forEach(async (doc) => {
            this.campaigns.push({
              documentId: doc.id,
              customerId: doc.data().customerId,
              customerCode: doc.data().customerCode,
              campaignId: doc.id,
              campaignCode: doc.data().campaignCode,
              campaignName: doc.data().name,
              isDeleted: doc.data().isDeleted,
              isUnlimited: doc.data().isUnlimited,
              limits: doc.data().limits,
            })
          })
        }
      )
    },

    // 検索ボタンを押した際の処理
    async searchCoupon(filterCouponCode: string) {
      this.isLoading = true
      this.searchedCoupons = []

      // ローディングフェイズ1
      this.couponProgressValue = 0
      this.loadingText = this.$t(
        'interviewDataSearch.progress.fetchingDataMessage'
      ) as string

      const results = (await this.getResultsByCouponCode(
        filterCouponCode
      )) as ResultType[]

      // ローディングフェイズ2
      this.loadingText = this.$t(
        'interviewDataSearch.progress.displayingSearchResultsMessage'
      ) as string

      // learnersからlearnerMail・learnerNameを取得
      let learnerMail = ''
      let learnerName = ''
      if (results === undefined || results.length === 0) {
        // console.log(filterCouponCode, 'はまだ受験結果がありません。')
      } else {
        const learnerId = results[0].userInfo.uid
        const learnerSnap = await getDoc(doc(db, 'learners', learnerId))
        if (learnerSnap.exists()) {
          learnerMail = learnerSnap.data().email
          learnerName = learnerSnap.data().displayName
        }

        const resultNum = results.length
        for (let i = 0; i < resultNum; i++) {
          const result = results[i]

          // this.campaigns内でcampaignId検索
          const campaignData = this.campaigns.filter(
            (item) => item.campaignId === result.userInfo.campaignId
          )[0]

          if (campaignData === undefined || campaignData.isDeleted === true) {
            continue
          }

          let campaignLimit = ''
          if (campaignData.isUnlimited === undefined) {
            campaignLimit = '-'
          } else if (campaignData.isUnlimited) {
            campaignLimit = '無制限'
          } else {
            campaignLimit = String(campaignData.limits)
          }

          // ticketのcouponCodeとcountを取得
          const campaignRef = doc(db, 'campaigns', campaignData.documentId)
          let ticketInfos = [] as TicketInfoType[]
          await getDocs(
            query(
              collection(db, 'learners', learnerId, 'tickets'),
              where('campaign', '==', campaignRef)
            )
          ).then((ticketData) => {
            ticketData.forEach((doc) => {
              let countIsUndefined: boolean
              let countTransport: number
              if (doc.data().customerOptions.count === undefined) {
                countIsUndefined = true
                countTransport = 0
              } else {
                countIsUndefined = false
                countTransport = doc.data().customerOptions.count
              }

              if (doc.data().customerOptions.couponCode === filterCouponCode) {
                ticketInfos.push({
                  documentId: doc.id,
                  confirmedAt: doc.data().customerOptions.confirmedAt,
                  countIsUndefined: countIsUndefined,
                  count: countTransport,
                  couponCode: doc.data().customerOptions.couponCode,
                  isAvailable: doc.data().customerOptions.isAvailable,
                  password: doc.data().customerOptions.password,
                  punchedAt: doc.data().customerOptions.punchedAt,
                })
              }
            })
          })

          if (ticketInfos.length === 0) {
            continue
          }

          this.searchedCoupons.push({
            id: i,
            learnerId: learnerId,
            campaignId: campaignData.campaignId,
            campaignCode: campaignData.campaignCode,
            campaignName: campaignData.campaignName,
            ticket: ticketInfos[0],
            isDeleted: campaignData.isDeleted,
            isUnlimited: campaignData.isUnlimited,
            limits: campaignLimit,
            couponCode: result.userInfo.couponCode,
            learnerMail: learnerMail,
            learnerName: learnerName,
            cefrScore: result.fastResult.score.cefrOverall.score,
            cefrLevel: result.fastResult.score.cefrOverall.cefrLevel,
          })
          this.couponProgressValue = Math.floor((i / resultNum) * 100) // プログレスバーの処理（もしかしたら、continueがあるif文の前の方がいいかも）
        }
      }
      // プログレスバーの初期化
      this.loadingText = this.$t(
        'interviewDataSearch.progress.longOperationWaitMessage'
      ) as string
      this.couponProgressValue = 0
      this.isLoading = false
    },

    async getResultsByCouponCode(couponCode: string) {
      const getResultsByCouponCodeForManagers = httpsCallable(
        functions,
        'getResultsByCouponCodeForManagers'
      )
      const inputData = {
        couponCode: couponCode,
      }
      // let uids = [] as string[]
      return await getResultsByCouponCodeForManagers(inputData)
        .then((result) => {
          const data = result.data
          return data
        })
        .catch((error) => {
          const code = error.code
          // const message = error.message
          // const details = error.details
          console.log('err code: ', code)
          // console.log('err message: ', message)
          // console.log('err details: ', details)
        })
    },

    // 再受験ボタン
    resumeBtn(resumeCoupon: SearchedCouponsType) {
      this.dialogConfirm = !this.dialogConfirm
      this.editingIndex = this.searchedCoupons.indexOf(resumeCoupon)
      this.editeingItem = resumeCoupon
    },

    // 再受験機能
    async resume() {
      this.dialogConfirm = !this.dialogConfirm
      const learnerId = this.editeingItem.learnerId
      const ticket = this.editeingItem.ticket
      await updateDoc(
        doc(db, 'learners', learnerId, 'tickets', ticket.documentId),
        {
          customerOptions: {
            confirmedAt: ticket.confirmedAt,
            count: ticket.count - 1,
            couponCode: ticket.couponCode,
            isAvailable: true,
            password: ticket.password,
            punchedAt: ticket.punchedAt,
          },
        }
      )
      this.searchCoupon(ticket.couponCode)
      this.isSnackbarAction = true
      this.snackbarText = this.$t(
        'interviewDataSearch.retestConfigurationCompletedMessage'
      ) as string
      this.snackbarColor = 'pink'
    },
  },
})
</script>
