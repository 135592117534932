<style scoped>
.card_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 23px #b9b9b9, -8px -8px 23px #ffffff !important;
}

.card_shadow_half {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #b9b9b9, -4px -4px 10px #ffffff !important;
}

.btn_shadow {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 10px #838383, -4px -4px 10px #ffffff !important;
}

.btn_shadow_half {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 5px #838383, -2px -2px 5px #ffffff !important;
}

.headline {
  background-color: #1b2643;
  border-radius: 3px;
  width: 60vw;
  height: 60px;
}

.card_headline {
  background-color: #1b2643;
  border-radius: 3px;
  height: 60px;
  color: white;
  font-size: 14px;
}

.card_headline_under {
  height: 1000px; /* ラインの高さ */
  background-color: #c7b0e8;
}

.active_text {
  border-bottom: 2px solid #ffffff;
  height: 60px;
}

.headline p {
  color: #ffffff;
  font-size: 14px;
}
td {
  border-bottom: 1px solid #dbdbdb;
}

.v-input {
  font-size: 14px;
}

.DLbtn {
  width: 5rem;
}

.displayName-column {
  min-width: 160px;
  max-width: 160px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>

<template>
  <div>
    <v-card width="60vw" class="ma-10 card_shadow">
      <v-form ref="form" lazy-validation @submit.prevent>
        <v-container class="ml-5 pt-5">
          <v-row class="headline ml-n8 mt-n5">
            <v-col class="active-text">
              <p>{{ $t('viewCouponLists.viewCouponList') }}</p>
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-10 mt-10 mb-1 text-body-2">
            <p>
              {{ $t('viewCouponLists.viewCampaignCouponListDescription') }}
            </p>
            <p>
              {{ $t('viewCouponLists.couponListModificationNote') }}
            </p>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-card width="60vw" class="ma-10 card_shadow">
      <template v-if="isLoadingCampaigns || isLoadingCoupons">
        <v-progress-linear indeterminate></v-progress-linear>
        <p class="w-full text-center primary--text text-caption">
          {{
            isLoadingCampaigns
              ? $t('viewCouponLists.progress.loadingCampaigns')
              : $t('viewCouponLists.progress.loadingCouponCounts')
          }}
        </p>
      </template>
      <v-form ref="form">
        <v-container class="mt-5">
          <v-row class="ml-5 mr-5">
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-html="
                          $t('viewCouponLists.tableHeader.campaignCodeOrName')
                        "
                      ></th>
                      <th
                        class="text-center"
                        v-html="
                          $t('viewCouponLists.tableHeader.totalCouponCounts')
                        "
                      ></th>
                      <th
                        class="text-center"
                        v-html="
                          $t(
                            'viewCouponLists.tableHeader.registeredCouponCounts'
                          )
                        "
                      ></th>
                      <th
                        class="text-center"
                        v-html="$t('viewCouponLists.tableHeader.couponList')"
                      ></th>
                    </tr>
                  </thead>
                  <tbody v-for="item in campaignData" :key="item.campaignCode">
                    <tr v-show="item.isShow && !item.isDeleted">
                      <td>
                        {{ item.campaignCode }}<br />({{ item.campaignName }})
                      </td>
                      <td class="text-center">{{ item.couponCount }}</td>
                      <td class="text-center">
                        {{
                          item.totalTicketCount === null
                            ? '...'
                            : item.totalTicketCount
                        }}
                      </td>
                      <td class="text-center">
                        <v-icon
                          class="mr-2"
                          @click="
                            getCoupons(item.campaignId),
                              (campaign = item),
                              (currentPage = 1)
                          "
                        >
                          mdi-list-box-outline
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-dialog v-model="isShowCoupons" width="700px">
              <v-card>
                <v-card-title class="justify-center card_headline">{{
                  $t('viewCouponLists.couponListDialog.couponList')
                }}</v-card-title>
                <v-card width="85%" class="mx-auto card_shadow_half mt-8">
                  <template v-if="isLoadingCoupons">
                    <v-progress-linear indeterminate></v-progress-linear>
                    <p class="w-full text-center primary--text text-caption">
                      {{ $t('viewCouponLists.progress.loadingCouponCounts') }}
                    </p>
                  </template>
                  <v-card-title>{{ campaign.campaignCode }}</v-card-title>
                  <v-card-subtitle>
                    {{ campaign.campaignName }}
                  </v-card-subtitle>
                  <v-card-text>
                    {{ $t('viewCouponLists.couponListDialog.totalCoupons') }}
                    {{ campaign.couponCount }}<br />
                    {{ $t('viewCouponLists.couponListDialog.usedCoupons') }}
                    {{ isLoadingCoupons ? '...' : campaign.totalTicketCount
                    }}<br />
                    {{ $t('viewCouponLists.couponListDialog.unusedCoupons') }}
                    {{
                      isLoadingCoupons
                        ? '...'
                        : campaign.couponCount -
                          (campaign.totalTicketCount || 0)
                    }}
                  </v-card-text>
                </v-card>
                <v-row
                  ><v-col class="mt-7 mb-n4">
                    <div
                      style="text-align: center"
                      v-html="
                        $t(
                          'viewCouponLists.couponListDialog.unusedCouponEditDescription'
                        )
                      "
                    ></div> </v-col
                ></v-row>
                <v-row
                  class="mt-5 mb-n10 text-body-2"
                  style="justify-content: center"
                >
                  <v-col cols="6">
                    <v-select
                      v-model="couponFilter"
                      :items="[
                        $t('viewCouponLists.couponListDialog.filterOption.all'),
                        $t(
                          'viewCouponLists.couponListDialog.filterOption.usedCouponsOnly'
                        ),
                        $t(
                          'viewCouponLists.couponListDialog.filterOption.unusedCouponsOnly'
                        ),
                      ]"
                      :label="$t('viewCouponLists.couponListDialog.filter')"
                      outlined
                      @input="filterCoupons"
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="mt-2">
                    <v-btn
                      class="DLbtn"
                      color="#1b2643"
                      dark
                      @click="downloadCsv(coupons)"
                    >
                      <v-icon color="white">mdi-folder-zip-outline</v-icon>
                      DL
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mx-16">
                  <v-col>
                    <v-pagination
                      v-model="currentPage"
                      :length="totalPageNum"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
                <v-row class="mt-2 text-body-2" style="text-align: center"
                  ><v-col v-if="currentPage * 10000 < campaign.couponCount"
                    >{{ (currentPage - 1) * 10000 + 1 }} ~
                    {{ currentPage * 10000 }}
                    {{ $t('viewCouponLists.couponListDialog.case') }} </v-col
                  ><v-col v-else
                    >{{ (currentPage - 1) * 10000 + 1 }} ~
                    {{ campaign.couponCount }}
                    {{ $t('viewCouponLists.couponListDialog.case') }}
                  </v-col>
                </v-row>
                <v-card
                  width="85%"
                  class="mx-auto mt-5 card_shadow_half"
                  elevation="0"
                >
                  <template v-if="isFetchingCoupons">
                    <v-progress-linear
                      v-model="couponLoadProgressValue"
                      color="primary"
                    >
                    </v-progress-linear>
                    <p class="w-full text-center primary--text text-caption">
                      {{
                        $t(
                          'viewCouponLists.couponListDialog.progress.loadingCouponList',
                          { progressValue: couponLoadProgressValue }
                        )
                      }}
                    </p>
                  </template>
                  <v-simple-table class="pr-5 pl-5 pt-2 pb-4">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            {{
                              $t(
                                'viewCouponLists.couponListDialog.tableHeader.no'
                              )
                            }}
                          </th>
                          <th>
                            {{
                              $t(
                                'viewCouponLists.couponListDialog.tableHeader.couponCode'
                              )
                            }}
                          </th>
                          <th>
                            {{
                              $t(
                                'viewCouponLists.couponListDialog.tableHeader.password'
                              )
                            }}
                          </th>
                          <th class="displayName-column">
                            {{
                              $t(
                                'viewCouponLists.couponListDialog.tableHeader.username'
                              )
                            }}
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  color="black"
                                  small
                                  class="ml-1"
                                  @click="
                                    toggleDisplayName = !toggleDisplayName
                                  "
                                >
                                  {{
                                    toggleDisplayName
                                      ? 'mdi mdi-eye-off'
                                      : 'mdi mdi-eye'
                                  }}
                                </v-icon>
                              </template>
                              <span>
                                {{
                                  toggleDisplayName
                                    ? $t(
                                        'viewCouponLists.couponListDialog.tableHeader.tooltipMessage.showNames'
                                      )
                                    : $t(
                                        'viewCouponLists.couponListDialog.tableHeader.tooltipMessage.hideNames'
                                      )
                                }}
                              </span>
                            </v-tooltip>
                          </th>
                          <th>
                            {{
                              $t(
                                'viewCouponLists.couponListDialog.tableHeader.edit'
                              )
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(item, index) in coupons[currentPage - 1]"
                        :key="item.couponCode"
                      >
                        <tr
                          v-show="
                            couponFilter === '全て' ||
                            item.isConfirmed === couponFilterBool
                          "
                        >
                          <td>{{ index + 1 + (currentPage - 1) * 10000 }}</td>
                          <td>{{ item.couponCode }}</td>
                          <td>{{ item.password }}</td>
                          <td class="displayName-column">
                            {{
                              toggleDisplayName && item.displayName
                                ? '****'
                                : item.displayName
                            }}
                          </td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              v-show="!item.isConfirmed"
                              @click="
                                ;(dialogEdit = true),
                                  (coupon = item),
                                  (couponCodeField = item.couponCode),
                                  (passwordField = item.password),
                                  (isConfirmedField = item.isConfirmed)
                              "
                              >{{
                                $t(
                                  'viewCouponLists.couponListDialog.tableHeader.editCoupon'
                                )
                              }}</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
                <v-row class="mt-2 mx-16">
                  <v-col>
                    <v-pagination
                      v-model="currentPage"
                      :length="totalPageNum"
                      circle
                      @input="toggleDisplayName = !toggleDisplayName"
                    ></v-pagination>
                  </v-col>
                </v-row>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="mx-2 mt-3 mb-3 px-5 btn_shadow"
                    color="#1b2643"
                    dark
                    @click="
                      {
                        isShowCoupons = false
                      }
                    "
                  >
                    {{
                      $t('viewCouponLists.couponListDialog.tableHeader.close')
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEdit" width="550px">
              <v-card class="mx-auto">
                <v-card-title class="justify-center card_headline">
                  {{ $t('viewCouponLists.couponEditDialog.couponEdit') }}
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    class="v-input mx-5 mt-10"
                    v-model="couponCodeField"
                    :label="$t('viewCouponLists.couponEditDialog.couponCode')"
                    persistent-placeholder
                    outlined
                    required
                    :disabled="coupon.isConfirmed"
                    :rules="[rules.required, rules.halfSize]"
                  ></v-text-field>
                  <v-text-field
                    class="v-input mx-5 mt-1 mb-n8"
                    v-model="passwordField"
                    :label="$t('viewCouponLists.couponEditDialog.password')"
                    persistent-placeholder
                    outlined
                    required
                    :disabled="coupon.isConfirmed"
                    :rules="[rules.required, rules.halfSize]"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-center pb-6">
                  <v-btn
                    class="btn_shadow px-7 white--text"
                    color="#1b2643"
                    @click="dialogEditConfirm = true"
                    :disabled="validation"
                  >
                    {{ $t('viewCouponLists.couponEditDialog.confirm') }}
                  </v-btn>
                  <v-btn
                    class="btn_shadow px-7 white--text"
                    color="error"
                    @click="dialogDelete = true"
                    :disabled="validation"
                  >
                    {{ $t('viewCouponLists.couponEditDialog.delete') }}
                  </v-btn>
                  <v-btn
                    class="btn_shadow"
                    color="#1b2643"
                    dark
                    @click="dialogEdit = false"
                  >
                    {{ $t('viewCouponLists.couponEditDialog.cancle') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEditConfirm" width="500px">
              <v-card class="mx-auto">
                <v-card-title class="justify-center card_headline">
                  {{
                    $t('viewCouponLists.couponEditConfirmDialog.couponEdit')
                  }}</v-card-title
                >
                <v-card-text>
                  <p class="text-center mt-6">
                    <b>{{
                      $t(
                        'viewCouponLists.couponEditConfirmDialog.confirmCouponEditMessage'
                      )
                    }}</b>
                  </p>
                  <v-card class="mx-3 card_shadow_half">
                    <v-card-text>
                      <p class="text-center">
                        {{
                          $t('viewCouponLists.couponEditConfirmDialog.before')
                        }}
                      </p>
                      {{
                        $t(
                          'viewCouponLists.couponEditConfirmDialog.couponCode'
                        )
                      }}{{ coupon.couponCode }}<br />
                      {{ $t('viewCouponLists.couponEditConfirmDialog.password')
                      }}{{ coupon.password }}
                    </v-card-text>
                  </v-card>
                  <v-row justify="center" class="my-5"
                    ><v-icon>mdi-arrow-down</v-icon></v-row
                  >
                  <v-card class="mx-3 card_shadow_half">
                    <v-card-text>
                      <p class="text-center">
                        {{
                          $t('viewCouponLists.couponEditConfirmDialog.after')
                        }}
                      </p>
                      {{
                        $t(
                          'viewCouponLists.couponEditConfirmDialog.couponCode'
                        )
                      }}{{ couponCodeField }}<br />
                      {{
                        $t('viewCouponLists.couponEditConfirmDialog.password')
                      }}
                      {{ passwordField }}
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions class="justify-center pb-6">
                  <v-btn
                    color="#1b2643"
                    class="btn_shadow px-7"
                    dark
                    @click="
                      editCoupon(
                        campaign.campaignId,
                        coupon,
                        couponCodeField,
                        passwordField,
                        coupons
                      ),
                        (dialogEdit = false),
                        (dialogEditConfirm = false)
                    "
                  >
                    {{ $t('viewCouponLists.couponEditConfirmDialog.edit') }}
                  </v-btn>
                  <v-btn
                    color="#1b2643"
                    class="btn_shadow"
                    dark
                    @click="dialogEditConfirm = false"
                  >
                    {{ $t('viewCouponLists.couponEditConfirmDialog.cancle') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" width="500px">
              <v-card class="mx-auto">
                <v-card-title class="justify-center card_headline">
                  {{
                    $t('viewCouponLists.couponDleteConfirmDialog.couponDelete')
                  }}
                </v-card-title>
                <v-card-text>
                  <p class="text-center mt-6">
                    <b>{{
                      $t(
                        'viewCouponLists.couponDleteConfirmDialog.confirmCouponDleteMessage'
                      )
                    }}</b>
                  </p>
                  <v-card class="mx-3 card_shadow_half">
                    <v-card-text>
                      {{
                        $t(
                          'viewCouponLists.couponDleteConfirmDialog.couponCode'
                        )
                      }}{{ coupon.couponCode }}<br />
                      {{
                        $t('viewCouponLists.couponDleteConfirmDialog.password')
                      }}{{ coupon.password }}
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions class="justify-center pb-5">
                  <v-btn
                    color="#1b2643"
                    class="btn_shadow px-7"
                    dark
                    @click="
                      deleteCoupon(campaign.campaignId, coupon.couponCode),
                        (dialogDelete = false),
                        (dialogEdit = false)
                    "
                  >
                    {{ $t('viewCouponLists.couponDleteConfirmDialog.delete') }}
                  </v-btn>
                  <v-btn
                    color="#1b2643"
                    class="btn_shadow"
                    dark
                    @click="dialogDelete = false"
                  >
                    {{ $t('viewCouponLists.couponDleteConfirmDialog.cancle') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogError" width="600px">
              <v-card class="mx-auto">
                <v-card-title class="justify-center card_headline">{{
                  $t('viewCouponLists.couponErrorDialog.error')
                }}</v-card-title>
                <v-card-text>
                  <p
                    class="text-center mt-6"
                    v-if="isConfirmedPresent"
                    v-html="
                      $t('viewCouponLists.couponErrorDialog.couponErrorMessage')
                    "
                  ></p>
                  <v-card class="mx-3 card_shadow_half">
                    <v-card-text>
                      {{ $t('viewCouponLists.couponErrorDialog.couponCode')
                      }}{{ coupon.couponCode }}<br />
                      {{ $t('viewCouponLists.couponErrorDialog.password')
                      }}{{ coupon.password }}
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="#1b2643"
                    class="px-5 mb-4 mt-n1 btn_shadow"
                    dark
                    @click="dialogError = false"
                  >
                    {{ $t('viewCouponLists.couponErrorDialog.close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { db } from '@/plugins/firebase'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  deleteDoc,
  setDoc,
  getDoc,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore'
import store from '@/store'
import moment from 'moment-timezone'
import { functions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

type CouponType = {
  couponCode: string
  password: string
  isConfirmed: boolean
  displayName: string
}

type CampaignType = {
  customerCode: string
  customerId: string
  campaignId: string
  campaignCode: string
  campaignName: string
  couponCount: number
  isShow: boolean
  isDeleted: boolean
  totalTicketCount: number | null
}

type StatusSummaryType = {
  availableTicketCount: number
  totalTicketCount: number
  usedTicketCount: number
}

type TicketStatusSummaryType = {
  ticketStatusSummary: StatusSummaryType[]
}

type CampaignCodeToStatusSummaryType = {
  [campaignCode: string]: StatusSummaryType
}

export default defineComponent({
  name: 'CouponsView',
  components: {},

  data() {
    return {
      campaignData: [] as CampaignType[],
      coupons: [] as CouponType[][],
      isShowCoupons: false,

      campaign: {} as CampaignType,
      coupon: {} as CouponType,

      couponField: {} as CouponType,
      couponCodeField: '',
      passwordField: '',
      isConfirmedField: false,

      dialogEdit: false,
      dialogEditConfirm: false,
      dialogDelete: false,
      dialogError: false,

      isConfirmedPresent: true,
      isUnique: true,

      toggleDisplayName: true,

      rules: {
        required: (value: string) => !!value || '未入力です',
        halfSize: (value: string) =>
          /^[a-zA-Z0-9_-]*$/.test(value) ||
          '半角英数字・ハイフン・アンダーバーで入力してください',
      },

      isFirstReader: true,
      lastCouponCode: '',
      currentPage: 1,
      totalPageNum: 0,
      couponFilter: '全て',
      couponFilterBool: true,
      campaignCodes: [] as string[],
      isLoadingCampaigns: false,
      isLoadingCoupons: false,
      isFetchingCoupons: false,
      couponLoadProgressValue: 0,
    }
  },

  watch: {
    isShowCoupons(val: boolean) {
      if (!val) {
        this.toggleDisplayName = true
      }
    },
    currentPage() {
      this.toggleDisplayName = false
    },
  },

  async mounted() {
    await this.readData()
  },

  computed: {
    validation() {
      let isDisabled = false
      // クーポンコードが未入力だった場合
      if (this.couponCodeField.length === 0) {
        isDisabled = true
      }
      // パスワードが未入力だった場合
      if (this.passwordField.length === 0) {
        isDisabled = true
      }
      // // クーポンコードに半角英数字・ハイフン・アンダーバー以外の文字が含まれていた場合
      if (!/^[a-zA-Z0-9_-]*$/.test(this.couponCodeField)) {
        isDisabled = true
      }
      // // パスワードに半角英数字・ハイフン・アンダーバー以外の文字が含まれていた場合
      if (!/^[a-zA-Z0-9_-]*$/.test(this.passwordField)) {
        isDisabled = true
      }
      return isDisabled
    },
  },

  methods: {
    async readData() {
      this.isLoadingCampaigns = true
      this.campaignData = []
      const campaignRef = collection(db, 'campaigns')
      const customerId = store.getters.customerId

      const campaignsSnapshot = await getDocs(
        query(
          campaignRef,
          where('customerId', '==', customerId),
          where('isDeleted', '==', false)
        )
      )

      campaignsSnapshot.forEach((doc) => {
        const data = doc.data()
        this.campaignCodes.push(data.campaignCode)
        this.campaignData.push({
          customerCode: data.customerCode,
          customerId: data.customerId,
          campaignId: doc.id,
          campaignCode: data.campaignCode,
          campaignName: data.name,
          couponCount: data.couponCount,
          isShow: true,
          isDeleted: false,
          totalTicketCount: null,
        })
      })

      this.isLoadingCampaigns = false

      // チケット登録済みクーポン数の取得
      await this.updateTicketStatusSummary()
    },

    async updateTicketStatusSummary() {
      this.isLoadingCoupons = true
      const getTicketStatusSummary = httpsCallable(
        functions,
        'getTicketStatusSummary'
      )

      const inputData = { campaignCodes: this.campaignCodes }
      try {
        const result = await getTicketStatusSummary(inputData)
        const data = result.data as TicketStatusSummaryType
        const campaignCodeToStatusSummaryType: CampaignCodeToStatusSummaryType =
          {}

        for (let campaignCode in data.ticketStatusSummary) {
          campaignCodeToStatusSummaryType[campaignCode] =
            data.ticketStatusSummary[campaignCode]
        }
        this.campaignData.forEach((campaign) => {
          campaign.totalTicketCount =
            campaignCodeToStatusSummaryType[
              campaign.campaignCode
            ].totalTicketCount
        })
      } catch (error) {
        console.log('err code: ', error)
      } finally {
        this.isLoadingCoupons = false
      }
    },

    // クーポンリスト表示時のフィルタ機能のための関数
    async filterCoupons() {
      if (this.couponFilter === 'チケット登録済みクーポンのみ') {
        this.couponFilterBool = true
      } else if (this.couponFilter === 'チケット未登録クーポンのみ') {
        this.couponFilterBool = false
      }
    },

    async fetchDisplayName(learnerId: string) {
      if (!learnerId) return ''
      const learnersDoc = (await getDoc(doc(db, 'learners', learnerId))).data()
      return learnersDoc?.displayName
    },

    // クーポンリスト表示時に使用するクーポンリスト取得のための関数
    async getCoupons(campaignId: string) {
      this.isShowCoupons = true
      this.isFetchingCoupons = true
      this.coupons = [] as CouponType[][]
      this.lastCouponCode = ''
      this.couponLoadProgressValue = 0
      this.totalPageNum = 0

      const fetchCoupons = async (startAfterCouponCode: string) => {
        return await getDocs(
          query(
            collection(db, 'campaigns', campaignId, 'coupons'),
            orderBy('couponCode'),
            startAfter(startAfterCouponCode),
            limit(5000)
          )
        )
      }

      let couponsSnapshot = await fetchCoupons(this.lastCouponCode)
      const couponNum: number = this.campaign.couponCount
      let remainingCouponNum: number = couponNum

      while (remainingCouponNum > 0) {
        let couponsPerPage = [] as CouponType[]
        // 1回のfetchで5000件のクーポンを取得し、2回fetchすることで1万件のクーポンを取得する
        for (let i = 0; i < 2; i++) {
          for (const coupon of couponsSnapshot.docs) {
            if (coupon.exists()) {
              const couponData = coupon.data()
              const displayName = await this.fetchDisplayName(
                couponData.learnerId
              )
              couponsPerPage.push({
                couponCode: couponData.couponCode,
                password: couponData.password,
                isConfirmed: couponData.isConfirmed,
                displayName: displayName,
              })
            }
          }
          this.lastCouponCode =
            couponsPerPage[couponsPerPage.length - 1].couponCode
          couponsSnapshot = await fetchCoupons(this.lastCouponCode)

          remainingCouponNum = Math.max(remainingCouponNum - 5000, 0)
        }

        this.couponLoadProgressValue = Math.floor(
          ((couponNum - remainingCouponNum) / couponNum) * 100
        )
        this.coupons.push(couponsPerPage)
      }
      this.isFetchingCoupons = false
      this.totalPageNum = Math.ceil(couponNum / 10000)
    },

    // クーポン編集時にチケット登録がされていないか確認するための関数
    async checkConfirmation(campaignId: string, couponCode: string) {
      return await getDoc(
        doc(db, 'campaigns', campaignId, 'coupons', couponCode)
      ).then((coupon) => {
        const isConfirmedPresent = coupon.data()?.isConfirmed
        this.isConfirmedPresent = isConfirmedPresent
        return isConfirmedPresent
      })
    },

    // クーポン編集時にクーポンコードが重複していないか確認するための関数
    async checkUniqueness(
      couponsList: CouponType[][],
      oldCouponCode: string,
      newCouponCode: string
    ) {
      let isUnique = true
      couponsList.forEach((coupons) => {
        coupons.forEach((coupon) => {
          if (
            coupon.couponCode === newCouponCode &&
            oldCouponCode !== newCouponCode
          ) {
            isUnique = false
          }
        })
      })
      this.isUnique = isUnique
      return isUnique
    },

    // クーポン編集のための関数
    async editCoupon(
      campaignId: string,
      coupon: CouponType,
      couponCodeField: string,
      passwordField: string,
      couponsList: CouponType[][]
    ) {
      const isUnique = await this.checkUniqueness(
        couponsList,
        coupon.couponCode,
        couponCodeField
      )
      const isConfirmedPresent = await this.checkConfirmation(
        campaignId,
        coupon.couponCode
      )
      if (!isConfirmedPresent && isUnique) {
        await deleteDoc(
          doc(db, 'campaigns', campaignId, 'coupons', coupon.couponCode)
        )
        await setDoc(
          doc(db, 'campaigns', campaignId, 'coupons', couponCodeField),
          {
            couponCode: couponCodeField,
            password: passwordField,
            isConfirmed: isConfirmedPresent,
          }
        )
        await this.getCoupons(campaignId)
      } else {
        this.dialogError = true
      }
    },

    // クーポン削除のための関数
    async deleteCoupon(campaignId: string, couponCode: string) {
      const isConfirmedPresent = await this.checkConfirmation(
        campaignId,
        couponCode
      )
      if (!isConfirmedPresent) {
        await deleteDoc(doc(db, 'campaigns', campaignId, 'coupons', couponCode))
        const campaignRef = await getDoc(doc(db, 'campaigns', campaignId))
        if (campaignRef.exists()) {
          let couponCount = campaignRef.data().couponCount - 1
          this.campaign.couponCount = couponCount
          await updateDoc(doc(db, 'campaigns', campaignId), {
            couponCount: couponCount,
          })
        }
        await this.getCoupons(campaignId)
      } else {
        this.dialogError = true
      }
    },

    // クーポンリストのcsvファイルダウンロード機能に関わる関数
    async downloadCsv(couponList: CouponType[][]) {
      var couponCsv =
        'CouponCode, Password, Ticket Registration, User Display Name'
      couponList.forEach((coupons) => {
        coupons.forEach((coupon) => {
          let couponCode = coupon.couponCode
          let password = coupon.password
          let displayName = coupon.displayName
          var ticket
          if (coupon.isConfirmed) {
            ticket = 'completed'
          } else {
            ticket = 'incomplete'
          }
          let couponLine =
            '\n' +
            couponCode +
            ', ' +
            password +
            ', ' +
            ticket +
            ', ' +
            displayName
          couponCsv += couponLine
        })
      })
      const blob = new Blob([couponCsv], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      let now = new Date(moment().tz('Asia/Tokyo').format())
      link.download = 'couponList_' + now.getTime() + '.csv'
      link.click()
      link.remove()
    },
  },
})
</script>
